<template>
  <!-- 定时策略 -->
  <div
    class="storage"
    v-loading.fullscreen.lock="loading"
    element-loading-text="请求数据中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >  
    <div class="append">
      <div class="seach">
        <ShopsAndSites
          v-if="tress.length != 0"
          style="margin-right: 10px"
          :list="tress"
          @getShopId="getID"
        ></ShopsAndSites>
        <el-tabs v-model="activeName" style="margin-top: 18px" @tab-click="handleSwitchClick">
          <el-tab-pane label="定时任务" name="timeTask">
            <el-row type="flex" justify="start" style="margin-top: 20px">
              <el-dropdown style="margin-left: 10px" @command="handleCommand">
                 <el-button icon="el-icon-circle-plus" type="primary">添加定时任务
                   <i class="el-icon-arrow-down el-icon--right"></i>
                 </el-button>
                 <el-dropdown-menu slot="dropdown" style="width: 150px">
                   <el-dropdown-item 
                    v-for="ele in operateTypeList"
                    :key="ele.id"
                    :command="ele.id"
                   >{{ ele.value }}
                   </el-dropdown-item>
                 </el-dropdown-menu>
              </el-dropdown>
              <el-button type="primary" icon="el-icon-open" @click="asyncEnable">启用</el-button>
              <el-button type="primary" icon="el-icon-turn-off" @click="asyncPause">暂停</el-button>
              <el-button type="danger" icon="el-icon-circle-close" @click="asyncDelete">删除</el-button>
              <el-select v-model="pageInfo.taskStatus" clearable placeholder="任务状态">
                <el-option
                  v-for="item in taskStatusList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-select v-model="pageInfo.objectType" clearable placeholder="任务对象">
                <el-option
                 v-for="item in taskObjectList"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
                ></el-option>
              </el-select>
              <el-input v-model="pageInfo.keyword" clearable placeholder="输入名称/定时任务ID"></el-input>
              <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
              <el-button type="primary" icon="el-icon-search" @click="asyncSearch">查询</el-button>
            </el-row>
            <!-- 列表区域 -->
            <el-table
              border
              :data="tableList"
              style="margin-top: 35px; width: 100%"  
              @selection-change="handleSelectionChange"   
              header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
            >
              <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column label="状态" align="center">
                  <template slot-scope="scope">
                    <div
                      style="
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        padding: 0 20px;
                      "
                    >
                      <el-tooltip effect="dark" content="已归档" placement="top">
                        <span
                          v-if="scope.row.status == 'archived'"
                          class="el-icon-document"
                        ></span>
                      </el-tooltip>
                      <el-switch
                        v-if="scope.row.status != 'archived'"
                        style="display: block"
                        v-model="scope.row.status"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-value="enabled"
                        inactive-value="paused"
                        @change="changeStatus(scope.row.id, scope.row.status)"
                      >
                      </el-switch>
                    </div>
                  </template>
              </el-table-column>
              <el-table-column label="名称" prop="name" align="center"></el-table-column>
              <el-table-column label="任务规则" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 'enabled'" style="color: #2E8B57">启动</span>
                  <span v-if="scope.row.type == 'paused'" style="color: #8B0000">暂停</span>
                  <span v-if="scope.row.type == 'change'" style="color: #1E90FF">更改</span>
                  <span v-if="scope.row.type == 'scheduled'" style="color: #0000FF">分时</span>
                  <span v-if="scope.row.type == 'strategy'" style="color: #708090">竞价策略</span>
                  <span v-if="scope.row.type == 'adjustment'" style="color: #2F4F4F">竞价调整 </span>
                </template>
              </el-table-column>
              <el-table-column label="应用范围" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.adType}}</span><br/>
                  <span>{{scope.row.objectRange}}</span>
                </template>
              </el-table-column>
              <el-table-column label="执行条件" prop="condition" align="center"></el-table-column>
              <el-table-column label="执行频率" prop="executeTime" align="center"></el-table-column>
              <el-table-column label="数据范围" prop="dataRange" align="center"></el-table-column>
              <el-table-column label="创建人" prop="createBy" align="center"></el-table-column>
              <el-table-column label="创建时间" prop="createTime" align="center" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-link type="primary" :disabled="scope.row.status == 'archived'?true:false" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-link>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="handleSizeChange1" 
              @current-change="handleCurrentChange1"
              layout="total, sizes, prev, pager, next, jumper" 
              :page-sizes="[20, 30, 50, 100]" 
              :total="totalNumber"
              :page-size="pageInfo.pageSize" 
              :current-page="pageInfo.current" 
              style="text-align: center; margin-top: 15px"
            >
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="执行记录" name="executionRecord">
            <el-row style="margin-top: 18px">
              <TimeQuantum
                style="margin-right:10px" 
                @selectFinish='topTime'
                section="近7天"
                @sectionFinish='sectionSure'
              ></TimeQuantum>
              <el-input clearable v-model="searchInfo.keyword" placeholder="输入名称/定时任务ID"></el-input>
              <el-button icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
              <el-button icon="el-icon-search" type="primary" @click="handle_Search">查询</el-button>
            </el-row>
            <el-table
              border
              :data="tableData2"
              v-loading="recordLoading"
              element-loading-text = "数据正在加载中"
              element-loading-spinner = "el-icon-loading"
              style="margin-top: 25px; width: 100%"
              header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
            >
              <el-table-column label="定时任务" prop="scheduleName" align="center"></el-table-column>
              <el-table-column label="任务规则" prop="type" align="center"></el-table-column>
              <el-table-column label="应用范围" prop="" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.adType}}</span><br/>
                  <span>{{scope.row.objectRange}}</span>
                </template>
              </el-table-column>
              <el-table-column label="执行条件" prop="condition" align="center"></el-table-column>
              <el-table-column label="执行时间" prop="executeTime" align="center"></el-table-column>
              <el-table-column label="执行结果" align="center">
                <template slot-scope="scope">
                  <el-link type="primary" @click="handleExecutionresult(scope.row)">{{scope.row.executeResult}}</el-link>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-link type="primary" @click="OpRecordsClick(scope.row)">广告操作记录</el-link>
                </template>
              </el-table-column>
            </el-table>
            <!-- 底部分页 -->
            <el-pagination
              @size-change="handleSizeChangerecords"
              @current-change="handleCurrentChangerecords"
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[20, 30, 50, 100]"
              :total="totalRecords"
              :page-size="searchInfo.pageSize"
              :current-page="searchInfo.current"
              style="margin-top: 25px; text-align: center"
            >
            </el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 添加定时任务(广告活动) -->
      <el-dialog
        width="40%"
        :visible.sync="adCampaignVisible"
      >
        <div slot="title">
          <span v-if="type1 == 1">添加定时任务(广告活动)</span>
          <span v-if="type1 == 2">编辑定时任务(广告活动)</span>
        </div>
        <el-form :label-position="labelPosition" :model="form" label-width="120px">
          <el-form-item label="任务名称">
            <el-input v-model="form.taskName" placeholder="请输入任务名称" style="width: 65%"></el-input>
          </el-form-item>
          <el-form-item label="为广告活动创建">
            <el-select 
              style="width: 65%"
              placeholder="请选择" 
              v-model="form.activityType" 
              :disabled="type1 == 2?true:false"
            >
              <el-option
                v-for="item in selectArray"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div v-show="form.activityType? true: false">
          <!-- 应用至广告活动 -->
          <el-row type="flex" justify="start">
            <h4 style="margin-right: 6px">应用至广告活动</h4>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                <span>您可以将任务对象应用到这一级别的所有广告系列广告活动、广告组、广告、关键词、商品定位和搜索词。</span><br/>
                <span>或者，您也可以在下面选中所需的复选框，仅将任务对象应用到选中的复选</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </el-row>
          <!-- 广告类型 -->
          <el-row style="margin: 26px 0 0 50px">
            <span style="margin-right: 20px">广告类型</span>
            <el-radio v-model="form.adTypes" label="sp">SP广告</el-radio>
          </el-row>
          <!-- 应用范围 -->
          <el-row type="flex" justify="start" style="margin: 10px 0 0 50px">
            <span style="width: 75px; margin-top: 15px">应用范围</span>
            <el-radio-group v-model="form.applicaRange">
              <el-radio label="enabled">所有已启动的广告活动</el-radio>
              <el-radio label="all">所有已启动和已暂停的广告活动</el-radio>
              <el-radio label="overbuget">所有超预算的广告活动</el-radio>
              <el-radio label="ids" style="margin-top: 15px;">
                <span @click="onClickAdCampaign" style="color: #1E90FF">选择广告活动</span>
              </el-radio>
            </el-radio-group>
          </el-row>
          <el-row v-show="form.applicaRange=='ids'? true: false">
            <el-table 
              border
              height="300"
              :data="tableDataArray"
              style="margin-top: 25px; width: 100%"
              header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
            >  
              <el-table-column label="广告活动" prop="name" align="center"></el-table-column>
              <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
              <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.state == 'paused'" style="color: #8B0000">暂停</span>
                  <span v-if="scope.row.state == 'enabled'" style="color: #2E8B57">启用</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-link :underline="false" type="primary" @click="handle_remove(scope.$index)">移除</el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <!-- 操作类型(竞价策略) -->
          <el-row 
            type="flex" 
            justify="start" 
            style="margin-top: 26px" 
            v-show="form.activityType=='strategy'?true:false"
          >
            <h4 style="width: 95px; margin-right: -20px; margin-top: 8px">操作类型</h4>
            <el-row type="flex" justify="start">
              <p style="margin: 10px 20px 0 0">在执行频率中将竞价策略调整到</p>
              <el-select placeholder="请选择" v-model="strategyType">
                <el-option label="动态竞价-只降低" value="down"></el-option>
                <el-option label="动态竞价-提高和降低" value="upDown"></el-option>
                <el-option label="固定降价" value="fixed"></el-option>
              </el-select>
            </el-row>
          </el-row>
          <!-- 操作类型(更改预算) -->
          <el-row 
            type="flex" 
            justify="start" 
            style="margin-top: 26px" 
            v-show="form.activityType =='change'? true:false"
          >
            <h4 style="width: 95px; margin-right: -20px; margin-top: 8px">操作类型</h4>
            <el-row type="flex" justify="start" >
              <el-select 
                v-model="executeType1"
                placeholder="选择操作类型"
                style="width: 130px; margin-right: 10px"
              >
                <el-option
                  v-for="item in operationTypeList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-input 
                style="width: 150px" 
                placeholder="请输入金额" 
                v-model="operationNumber" 
                v-show="executeType1=='fixed'?true:false"
              ></el-input>
              <div v-show="executeType1=='up'?true:false">
                <el-row type="flex" justify="start">
                  <el-select style="width: 150px; margin-right: 8px" v-model="operationMode">
                    <el-option label="按百分比提高" value="rate"></el-option>
                    <el-option label="按固定金额增加" value="fixed"></el-option>
                  </el-select>
                  <el-input style="width: 100px" v-model="operationNumber"></el-input>
                  <p style="margin: 8px 12px 0 10px">预算上限</p>
                  <el-input style="width: 130px" v-model="operationMaxLimit" placeholder="输入预算上限"></el-input>
                </el-row>
              </div>
              <div v-show="executeType1=='down'?true:false">
                <el-row type="flex" justify="start">
                  <el-select style="width: 150px; margin-right: 8px" v-model="operationMode">
                    <el-option label="按百分比降低" value="rate"></el-option>
                    <el-option label="按固定金额减少" value="fixed"></el-option>
                  </el-select>
                  <el-input style="width: 100px" v-model="operationNumber"></el-input>
                  <p style="margin-top: 8px; margin-left: 10px; margin-right: 10px">预算下限</p>
                  <el-input style="width: 130px" v-model="operationMinLimit" placeholder="输入预算下限"></el-input>
                </el-row>
              </div>
            </el-row>
          </el-row>
          <!-- 执行条件 -->
          <el-row type="flex" justify="start" style="margin-top: 26px" v-show="form.activityType !='scheduled'? true:false">
            <h4 style="margin-right: 8px; margin-top: 2px">执行条件</h4>
            <el-tooltip effect="dark" placement="top" content="满足执行条件的要求定时任务才会生效">
              <p class="el-icon-question" style="margin-right: 15px; margin-top: 2px"></p>
            </el-tooltip>
            <el-popover
              width="500"
              placement="right"
              trigger="click"
              v-model="showVisible">
              <el-row v-for="(item, _index) in dynamicDomain" :key="_index" style="margin-bottom: 15px">
                <el-select
                  placeholder="执行条件" 
                  v-model="item.conditional" 
                  style="width: 150px; margin-right: 10px"
                >
                <el-option
                  v-for="item in performsConditional"
                  :key="item.id"
                  :label="item.values"
                  :value="item.id"
                  @click.native="toggleSwitch(item, _index)"
                ></el-option>
                </el-select>
                <!-- id为ctr和cvr和acos -->
                <el-select 
                  v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false"
                  v-model="item.compare" 
                  placeholder="请选择" 
                  style="width: 150px; margin-right: 10px"
                >
                  <el-option
                    v-for="item in JudgeConditions11"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                    @click.native="toggleChange(item, _index)"
                  ></el-option>
                </el-select>
                <!-- id为非ctr和cvr和acos -->
                <el-select 
                  v-show="item.conditional=='impressions'||item.conditional=='clicks'||item.conditional=='cost'||item.conditional=='ordered'||item.conditional=='sales'||item.conditional==''?true:false"
                  v-model="item.compare" 
                  placeholder="请选择" 
                  style="width: 150px; margin-right: 10px"
                >
                  <el-option
                    v-for="item in JudgeConditions22"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                    @click.native="toggleChange(item, _index)"
                  ></el-option>
                </el-select>
                <el-input v-model="item.amount" placeholder="请输入" style="width: 150px; margin-right: 10px">
                  <i slot="suffix" v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false">
                    <img src="../../assets/common/percent.png" style="width: 26px; height: 26px; margin-top: 2px">
                  </i>
                  <i slot="suffix" v-show="item.conditional=='cost'||item.conditional=='sales'?true:fales">
                    <img src="../../assets/common/Jpy.png" style="width: 30px; height: 30px; margin-top: 1px; margin-left: 20px">
                  </i>
                </el-input>
                <p class="el-icon-close removeClass" @click="handleDelete(_index)"></p>
              </el-row>
              <el-row style="margin-top: 10px">
                <el-button type="text" icon="el-icon-plus" :disabled="dynamicDomain.length==5?true:false" @click="handlePlus">添加</el-button>
              </el-row>
              <el-row type="flex" justify="end">
                <el-button size="mini" @click="showVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="handleConfirm">确定</el-button>
              </el-row>
              <el-link :underline="false" type="primary" slot="reference">添加条件</el-link>
            </el-popover>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-tag
              size="medium"
              closable
              :key="tag"
              v-for="(tag, index) in dynamicTags"
              @close="handleClose1(tag, index)"
              style="margin-left: 15px"
              >{{tag}}
            </el-tag>
          </el-row>
          <el-row type="flex" justify="start" style="margin-top: 25px" v-show="adCampaignTimeRange">
            <span style="margin-right: 15px; margin-top: 5px">执行条件的时间范围</span>
            <el-select 
              size="mini"
              placeholder="请选择"
              v-model="adCampaignTimeRangeValue" 
              style="width: 120px"
            >
              <el-option 
               v-for="item in appTimeRangeList"
               :key="item.id"
               :label="item.name"
               :value="item.id"
              ></el-option>
            </el-select>
          </el-row>
          <!-- 执行频率 -->
          <el-row type="flex" justify="start" style="margin-top: 35px" v-show="form.activityType !='scheduled'? true:false">
            <h4 style="margin-right: 8px; margin-top: 8px">执行频率</h4>
            <el-tooltip effect="dark" placement="top" content="请指定执行相应操作的执行频率">
              <span class="el-icon-question" style="margin-right: 15px; margin-top: 8px"></span>
            </el-tooltip>
            <el-select 
             size="small" 
             style="width: 150px"
             v-model="campaignFrequency"
            >
              <el-option
                v-for="item in ExecutionFrequency"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 一次 -->
            <el-date-picker
              v-show="campaignFrequency=='0'?true:false"
              v-model="chooseDateTime"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm" 
              style="width: 200px; margin-left: 10px"
              >
            </el-date-picker>
            <!-- 每日 -->
            <el-time-picker
              v-show="campaignFrequency=='1'?true:false"
              v-model="chooseTimePoint"
              format="HH:mm"
              value-format="HH:mm" 
              type="datetime"
              placeholder="时间点"
              style="width: 160px; margin-left: 10px">
            </el-time-picker>
            <!-- 每周 -->
            <div v-show="campaignFrequency=='2'?true:false">
             <el-select 
               v-model="adCampaignExecuteDay"
               placeholder="请选择天"
               style="width: 110px; margin-left: 10px"
             >
               <el-option
                 v-for="item in oneWeekList"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
               ></el-option>
             </el-select>
             <el-time-picker
               v-model="chooseTimePoint"
               format="HH:mm"
               type="datetime" 
               value-format="HH:mm"
               placeholder="时间点"
               style="width: 160px; margin-left: 10px"
               >
             </el-time-picker>
            </div>
            <!-- 每月 -->
            <div v-show="campaignFrequency=='3'?true:false">
              <el-select 
                v-model="adCampaignExecuteDay"
                placeholder="选择天"
                style="width: 120px; margin-left: 10px"
              >
                <el-option
                 v-for="item in oneMonthDate"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
                ></el-option>
              </el-select>
              <el-time-picker
               v-model="chooseTimePoint"
               type="datetime"
               format="HH:mm"
               value-format="HH:mm" 
               placeholder="时间点"
               style="width: 180px; margin-left: 10px"
              ></el-time-picker>
            </div>
          </el-row>
          <!-- 分时调预算 -->
          <el-row style="margin-top: 35px" v-show="form.activityType =='scheduled'?true:false">
            <el-row type="flex" justify="start">
              <h4 style="margin: 8px 15px 0 0">分时调预算</h4>
              <el-radio v-model="a1" label="1" style="margin-top: 8px">设置基础预算</el-radio>
              <el-input size="small" placeholder="输入基础预算" v-model.number="timeDivisionBudget" style="width: 150px"></el-input>
              <el-radio v-model="a1" label="2" style="margin: 8px 0 0 25px">使用广告活动原始预算为基础预算</el-radio>
            </el-row>
            <el-row style="margin-top: 30px">
              <span>说明：定时任务会按照【基础预算】上下调整对象的预算，不设置则选择调整对象的原始预算为基础预算</span>
            </el-row>
            <el-row style="margin-top: 25px; margin-bottom: 25px">
              <el-select 
               size="mini" 
               style="width: 120px; margin-right: 20px"
               v-model="dayType"
               @change="handlePresetIn"
              >
                <el-option label="按天" value="1"></el-option>
                <el-option label="按周" value="2"></el-option>
              </el-select>
              <el-tooltip effect="dark" placement="top" content="按天最多只能设置两个时间段">
                <el-button size="medium" type="text" @click="addTimeRange">添加时间段</el-button>
              </el-tooltip>
            </el-row>
            <!-- 按周添加 -->
            <el-row v-for="(item, index) in addTimeRangeByWeek" :key="index" style="margin-bottom: 10px">
              <el-select
                v-if="dayType == '2'"
                v-model="item.severalWeeks" 
                style="width: 130px; 
                margin-right: 15px" 
                placeholder="请选择周几"
              >
                <el-option 
                  v-for="item in oneWeekList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-time-picker
                placeholder="开始时间"
                v-model="item.startTime"
                format='HH:mm'      
                value-format="HH:mm"
                :picker-options="{selectableRange:`00:00:00-${item.endTime ? item.endTime + ':00' : '23:59:59'}`}"
                style="width: 150px; margin-right: 15px"
              >
              </el-time-picker>
              <el-time-picker
                placeholder="结束时间"
                v-model="item.endTime"
                format='HH:mm'      
                value-format="HH:mm"
                :picker-options="{selectableRange:`${item.startTime ? item.startTime + ':00' : '00:00:00'}-23:59:59`}"
                style="width: 150px; margin-right: 15px"
              >
              </el-time-picker>
              <el-select placeholder="修改方式" v-model="item.modifyWay" style="width: 160px; margin-right: 15px">
                <el-option label="直接输入金额" value="0"></el-option>
                <el-option label="按百分比提高" value="1"></el-option>
                <el-option label="按百分比降低" value="2"></el-option>
                <el-option label="按固定金额增加" value="3"></el-option>
                <el-option label="按固定金额减少" value="4"></el-option>
              </el-select>
              <el-input v-model="item.inputAmount" style="width: 80px"></el-input>
              <span class="el-icon-close deleteButton" @click="handleRemoveByCampaign(index)"></span>
            </el-row>
          </el-row>
          <!-- 执行通知 -->
          <el-row style="margin-top: 40px">
            <h4>执行通知</h4>
            <el-row v-show="noticeTime=='0'?false:true" style="margin-top: 18px; margin-bottom: 18px; margin-left: 50px">
              <span style="margin-right: 15px">通过邮件</span>
              <el-input v-model="notificationEmail" style="width: 40%" placeholder="请输入邮箱账号"></el-input>
            </el-row>
            <el-row style="margin-left: 50px">
              <span style="margin-right: 15px">发送结果</span>
              <el-select 
               v-model="noticeTime" 
               style="width: 40%" 
               placeholder="请选择通知时间"
              >
                <el-option
                  v-for="item in sendResultsList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-row>
          </el-row>
        </div>
        <span slot="footer">
          <el-button @click="adCampaignVisible = false">取 消</el-button>
          <el-button v-if="type1 == 1" type="primary" @click="adCampaignConfirm(1)">确 定</el-button>
          <el-button v-if="type1 == 2" type="primary" @click="adCampaignConfirm(2)">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 选择执行对象(广告活动) -->
      <el-dialog
        width="40%"
        title="选择执行对象"
        :visible.sync="performObjectVisible"
      >
        <el-row type="flex" justify="start">
          <el-input
            clearable
            placeholder="请输入广告活动" 
            v-model="pageList.adCampaign" 
            style="margin-right: 18px; width: 230px"
          ></el-input>
          <el-button icon="el-icon-search" type="primary" @click="tableSearch">搜索</el-button>
        </el-row>
        <el-table
          border
          height="600"
          row-key="campaignId"
          ref="multipleTable"
          :data="tableDataObject"
          style="margin-top: 35px; width: 100%" 
          @selection-change="handleSelectionChange2"
        >
          <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
          <el-table-column label="广告活动" prop="name" align="center"></el-table-column>
          <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.state == 'enabled'" style="color: #2E8B57">启用</span>
              <span v-if="scope.row.state == 'paused'" style="color: #8B0000">暂停</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :total="total"
          :page-size="pageList.pageSize"
          :current-page="pageList.current"
          style="margin-top: 25px; text-align: center"
        >
        </el-pagination>
        <span slot="footer">
          <el-button @click="performObjectVisible = false">取 消</el-button>
          <el-button type="primary" @click="asyncConfirm">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 添加定时任务(广告组) -->
      <el-dialog
        width="40%"
        :visible.sync="adGroupVisible"  
      >
        <div slot="title">
          <span v-if="type2 == 1">添加定时任务(广告组)</span>
          <span v-if="type2 == 2">编辑定时任务(广告组)</span>
        </div>
        <el-form :label-position="labelPosition" :model="formData" label-width="120px">
          <el-form-item label="任务名称">
            <el-input v-model="formData.taskName" placeholder="请输入任务名称" style="width: 65%"></el-input>
          </el-form-item>
          <el-form-item label="为广告组创建">
            <el-select 
              style="width: 65%"
              placeholder="请选择" 
              v-model="formData.adGroupType"
              :disabled="type2 == 2?true:false"
            >
              <el-option label="启动广告组" value="enabled"></el-option>
              <el-option label="暂停广告组" value="paused"></el-option>
              <el-option label="更改竞价" value="change"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div v-show="formData.adGroupType? true:false">
          <!-- 应用至广告组 -->
          <el-row type="flex" justify="start">
            <h4 style="margin-right: 6px">应用至广告组</h4>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                <span>您可以将任务对象应用到这一级别的所有广告系列广告活动、广告组、广告、关键词、商品定位和搜索词。</span><br/>
                <span>或者，您也可以在下面选中所需的复选框，仅将任务对象应用到选中的复选</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </el-row>
          <!-- 广告类型 -->
          <el-row style="margin: 26px 0 0 50px">
            <span style="margin-right: 20px">广告类型</span>
            <el-radio v-model="formData.adGroupTypes" label="sp">SP广告</el-radio>
          </el-row>
          <!-- 应用范围 -->
          <el-row type="flex" justify="start" style="margin: 10px 0 0 50px">
            <span style="width: 75px; margin-top: 15px">应用范围</span>
            <el-radio-group v-model="formData.adGroupAppRange">
              <el-radio label="enabled">所有已启动的广告组</el-radio>
              <el-radio label="all">所有已启动和已暂停的广告组</el-radio>
              <el-radio label="ids" style="margin-top: 15px">
                <span @click="onClickAdGroup" style="color: #1E90FF">选择广告组</span>
              </el-radio>
            </el-radio-group>
          </el-row>
          <!-- 增加表格 -->
          <el-row v-show="formData.adGroupAppRange =='ids'?true:false">
            <el-table
             border
             height="300"
             :data="adGroupTableList"
             style="margin-top: 25px; width: 100%"
             header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
            >
              <el-table-column label="广告组" prop="name" align="center"></el-table-column>
              <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
              <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.state == 'enabled'" style="color: #2E8B57">启用</span>
                  <span v-if="scope.row.state == 'paused'" style="color: #8B0000">暂停</span>
                </template>
              </el-table-column>
              <el-table-column label="所属广告活动" prop="adGroupName" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-link :underline="false" type="primary" @click="adGroupRemove(scope.$index)">移除</el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <!-- 操作类型 -->
          <el-row
            type="flex" 
            justify="start" 
            style="margin-top: 20px" 
            v-show="formData.adGroupType=='change'? true:false"
          >
            <h4 style="width: 95px; margin-right: -20px; margin-top: 8px">操作类型</h4>
            <el-row type="flex" justify="start">
              <el-select 
               placeholder="选择操作类型"
               v-model="adGroupOperation"
               style="width: 130px; margin-right: 10px"
              >
                <el-option
                  v-for="item in operationTypeList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-input 
                style="width: 150px" 
                placeholder="请输入金额"
                v-model.number="adGroupNumber" 
                v-show="adGroupOperation=='fixed'?true:false"
              ></el-input>
              <div v-show="adGroupOperation=='up'?true:false">
                <el-row type="flex" justify="start">
                  <el-select style="width: 120px; margin-right: 8px" v-model="adGroupMode">
                    <el-option label="按百分比提高" value="rate"></el-option>
                    <el-option label="按固定金额增加" value="fixed"></el-option>
                  </el-select>
                  <el-input v-model="adGroupNumber" style="width: 100px"></el-input>
                  <p style="margin: 8px 15px 0 15px">预算上限</p>
                  <el-input style="width: 130px" v-model="adGroupMaxLimit" placeholder="输入预算上限"></el-input>
                </el-row>
              </div>
              <div v-show="adGroupOperation=='down'?true:false">
                <el-row type="flex" justify="start">
                  <el-select style="width: 120px; margin-right: 8px" v-model="adGroupMode">
                    <el-option label="按百分比降低" value="rate"></el-option>
                    <el-option label="按固定金额减少" value="fixed"></el-option>
                  </el-select>
                  <el-input style="width: 100px" v-model="adGroupNumber"></el-input>
                  <p style="margin: 8px 15px 0 15px">预算下线</p>
                  <el-input style="width: 130px" v-model="adGroupMinLimit" placeholder="输入预算下限"></el-input>
                </el-row>
              </div>
            </el-row>
          </el-row>
          <!-- 执行条件 -->
          <el-row type="flex" justify="start" style="margin-top: 35px;">
            <h4 style="margin: 2px 8px 0 0">执行条件</h4>
            <el-tooltip effect="dark" placement="top" content="执行条件要求的定时任务才会生效">
              <p class="el-icon-question" style="margin-right: 15px; margin-top: 2px"></p>
            </el-tooltip>
            <el-popover
              width="500"
              placement="right"
              trigger="click"
              v-model="addConditShow"
            >
             <el-row v-for="(item, index2) in gridData" :key="index2" style="margin-bottom: 15px">
               <el-select
                placeholder="执行条件"
                v-model="item.conditional"
                style="width: 150px; margin-right: 10px"
               >
                <el-option
                 v-for="item in performsConditional"
                 :key="item.id"
                 :label="item.values"
                 :value="item.id"
                 @click.native="adGroupToggleConditions(item, index2)"
                ></el-option>
               </el-select>
               <!-- id为ctr和cvr和acos -->
               <el-select
                v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false"
                v-model="item.compare"
                placeholder="请选择"
                style="width: 150px; margin-right: 10px"
               >
                <el-option
                  v-for="item in JudgeConditions11"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                  @click.native="adGroupChangeCompare(item, index2)"
                >
                </el-option>
               </el-select>
               <!-- id为非ctr和cvr和acos -->
               <el-select
                v-show="item.conditional=='impressions'||item.conditional=='clicks'||item.conditional=='cost'||item.conditional=='ordered'||item.conditional=='sales'||item.conditional==''?true:false"
                v-model="item.compare"
                placeholder="请选择"
                style="width: 150px; margin-right: 10px"
               >
                <el-option
                  v-for="item in JudgeConditions22"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                  @click.native="adGroupChangeCompare(item, index2)"
                >
                </el-option>
               </el-select>
               <el-input v-model="item.amount" placeholder="请输入" style="width: 150px; margin-right: 10px">
                 <i slot="suffix" v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false">
                    <img src="../../assets/common/percent.png" style="width: 26px; height: 26px; margin-top: 2px">
                 </i>
                 <i slot="suffix" v-show="item.conditional=='cost'||item.conditional=='sales'?true:fales">
                    <img src="../../assets/common/Jpy.png" style="width: 30px; height: 30px; margin-top: 1px; margin-left: 20px">
                 </i>
               </el-input>
               <p class="el-icon-close removeClass" @click="deleteEvent(index)"></p>
             </el-row>
             <el-row style="margin-top: 10px">
               <el-button 
                type="text" 
                icon="el-icon-plus" 
                @click="handleAdd"
                :disabled="gridData.length == 5 ? true: false"
               >添加</el-button>
             </el-row>
             <el-row type="flex" justify="end"> 
              <el-button size="mini" @click="addConditShow = false">取消</el-button>
              <el-button size="mini" type="primary" @click="onClickConfirm">确定</el-button>
             </el-row>
             <el-link :underline="false" type="primary" slot="reference">添加条件</el-link>
            </el-popover>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-tag
              size="medium"
              closable
              :key="tag"
              v-for="(tag, index) in dynamicTags2"
              @close="handleClose2(tag, index)"
              style="margin-left: 15px"
            >{{tag}}
            </el-tag>
          </el-row>
          <el-row type="flex" justify="start" style="margin-top: 25px" v-show="adGroupTimeRange">
            <span style="margin: 5px 15px 0 0">执行条件的时间范围</span>
            <el-select
              size="mini"
              placeholder="请选择"
              v-model="adGroupValue" 
              style="width: 120px"
            >
              <el-option
                v-for="item in appTimeRangeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-row>
          <!-- 执行频率 -->
          <el-row type="flex" justify="start" style="margin-top: 25px;">
            <h4 style="margin-right: 8px; margin-top: 8px">执行频率</h4>
            <el-tooltip effect="dark" placement="top" content="请指定执行相应操作的执行频率">
              <span class="el-icon-question" style="margin-right: 15px; margin-top: 8px"></span>
            </el-tooltip>
            <el-select
              size="small"
              style="width: 150px"
              v-model="adGroupFrequency"
            >
              <el-option
                v-for="item in ExecutionFrequency"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 一次 -->
            <el-date-picker
              v-show="adGroupFrequency=='0'?true:false"
              v-model="adGroupDateTime"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm" 
              style="width: 200px; margin-left: 10px"
            ></el-date-picker>
            <!-- 每日 -->
            <el-time-picker
              v-show="adGroupFrequency=='1'?true:false"
              v-model="adGroupTimePoint"
              format="HH:mm"
              value-format="HH:mm" 
              type="datetime"
              placeholder="时间点"
              style="width: 160px; margin-left: 10px"
            >
            </el-time-picker>
            <!-- 每周 -->
            <div v-show="adGroupFrequency=='2'?true:false">
              <el-select
                v-model="adGroupExecuteDay"
                placeholder="请选择天"
                style="width: 110px; margin-left: 10px"
              >
               <el-option
                v-for="item in oneWeekList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
               ></el-option>
              </el-select>
              <el-time-picker
                v-model="adGroupTimePoint"
                type="datetime"
                format="HH:mm"
                value-format="HH:mm"
                placeholder="时间点"
                style="width: 160px; margin-left: 10px"
              ></el-time-picker>
            </div>
            <!-- 每月 -->
            <div v-show="adGroupFrequency=='3'?true:false">
              <el-select
                v-model="adGroupExecuteDay"
                placeholder="选择天"
                style="width: 120px; margin-left: 10px"
              >
                <el-option
                 v-for="item in oneMonthDate"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
                ></el-option>
              </el-select>
              <el-time-picker
                v-model="adGroupTimePoint"
                type="datetime"
                format="HH:mm"
                value-format="HH:mm"
                placeholder="时间点"
                style="width: 180px; margin-left: 10px"
              ></el-time-picker>
            </div>
          </el-row>
          <!-- 执行通知 -->
          <el-row style="margin-top: 40px">
            <h4>执行通知</h4>  
            <el-row v-show="adGroupNoticeTime=='0'?false:true" style="margin: 18px 0 18px 50px">
              <span style="margin-right: 15px">通过邮件</span>
              <el-input v-model="adGroupEmail" clearable style="width: 40%" placeholder="请输入邮箱账号">
              </el-input>
            </el-row>
            <el-row>
              <span style="margin-right: 15px; margin-left: 50px">发送结果</span>
              <el-select
                style="width: 38%" 
                v-model="adGroupNoticeTime"
                placeholder="请选择通知时间"
              >
               <el-option
                v-for="item in sendResultsList"   
                :key="item.id"      
                :label="item.value"
                :value="item.id"      
               ></el-option>
              </el-select>
            </el-row>
          </el-row>
        </div>
        <span slot="footer">
          <el-button @click="adGroupVisible = false">取消</el-button>
          <el-button v-if="type2 == 1" type="primary" @click="adGroupConfirm(1)">确定</el-button>
          <el-button v-if="type2 == 2" type="primary" @click="adGroupConfirm(2)">确定</el-button>
        </span>
      </el-dialog>
      <!-- 选择执行任务对象(广告组) -->
      <el-dialog
        width="40%"
        title="选择执行对象"
        :visible.sync="adGroupObjectVisible"
      > 
        <el-row type="flex" justify="start">
          <el-select 
           clearable
           filterable 
           style="margin-right: 15px" 
           placeholder="广告活动"
           v-model="pageInfoAdGroup.adGroup"
          >
            <el-option
              v-for="item in adGroupSelectData"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            <p>
              <span>({{item.type}})</span>-
              <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
              <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont  icon-zantingtingzhi"></span>
              <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont  icon-guidang"></span>
              <span>{{item.value}}</span>
            </p>
            </el-option>
          </el-select>
          <el-input v-model="pageInfoAdGroup.keyword" clearable style="margin-right: 18px; width: 200px" placeholder="搜索广告组"></el-input>
          <el-button icon="el-icon-search" type="primary" @click="handleadGroupSearch">搜索</el-button>
        </el-row>
        <el-table
          border
          height="600"
          row-key="adGroupId"
          ref="multipleTable2"
          :data="adGroupTableData"
          style="margin-top: 35px; width: 100%"
          @selection-change="handleSelectionChange3"
        >
          <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
          <el-table-column label="广告组" prop="name" align="center"></el-table-column>
          <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.state == 'enabled'" style="color: #2E8B57">启用</span>
              <span v-if="scope.row.state == 'paused'" style="color: #8B0000">暂停</span>
            </template>
          </el-table-column>
          <el-table-column label="所属广告活动" prop="adGroupName" align="center"></el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          @size-change="asyncSizeChange"
          @current-change="asyncCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :total="adGroupTotal"
          :page-size="pageInfoAdGroup.pageSize"
          :current-page="pageInfoAdGroup.current"
          style="margin-top: 25px; text-align: center"
        >
        </el-pagination>
        <span slot="footer">
          <el-button @click="adGroupObjectVisible = false">取 消</el-button>
          <el-button type="primary" @click="addAdGroupConfirm">确 认</el-button>
        </span> 
      </el-dialog>

      <!-- 添加定时任务(广告位) -->
      <AdvertiseDialog 
        @showDialog="openShow"
        ref="subComponents"
        :profileId="pageInfo.profileId"
        :editId="editId"
      ></AdvertiseDialog>

      <!-- 添加定时任务广告(广告) -->
      <el-dialog
       width="40%"
       :visible.sync="advertiseVisible"
      >
        <div slot="title">
          <span v-if="type4 == 1">添加定时任务广告(广告)</span>
          <span v-if="type4 == 2">编辑定时任务广告(广告)</span>
        </div>
        <el-form :label-position="labelPosition" :model="formInline" label-width="120px">
          <el-form-item label="任务名称">
            <el-input v-model="formInline.taskName" placeholder="请输入任务名称" style="width: 65%"></el-input>
          </el-form-item>
          <el-form-item label="为广告创建">
            <el-select 
              style="width: 65%"
              placeholder="请选择"
              v-model="formInline.createAdver" 
              :disabled="type4==2?true:false"
            >
              <el-option label="启动广告" value="enabled"></el-option>
              <el-option label="暂停广告" value="paused"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div v-show="formInline.createAdver?true:false">
          <!-- 应用至广告活动 -->
          <el-row type="flex" justify="start">
            <h4 style="margin-right: 6px">应用至广告活动</h4>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                <span>您可以将任务对象应用到这一级别的所有广告系列广告活动、广告组、广告、关键词、商品定位和搜索词。</span><br/>
                <span>或者，您也可以在下面选中所需的复选框，仅将任务对象应用到选中的复选</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </el-row>
          <!-- 广告类型 -->
          <el-row style="margin:26px 0 0 50px">
            <span style="margin-right: 20px">广告类型</span>
            <el-radio v-model="formInline.advertisingTypes" label="sp">SP广告</el-radio>
          </el-row>
          <!-- 应用范围 -->
          <el-row type="flex" justify="start" style="margin:10px 0 0 50px">
            <span style="width: 75px; margin-top: 15px">应用范围</span>
            <el-radio-group v-model="formInline.advertiseApplicaRange">
              <el-radio label="enabled">所有已启动的广告</el-radio>
              <el-radio label="all">所有已启动和已暂停的广告</el-radio>
              <el-radio label="ids" style="margin-top: 15px;">
                <span @click="onClickAdver" style="color: #1E90FF">选择广告</span>
              </el-radio>
            </el-radio-group>
          </el-row>
          <!-- table表格显示与隐藏 -->
          <el-row v-show="formInline.advertiseApplicaRange=='ids'?true:false">
            <el-table
              border
              height="300"
              :data="adTableList"
              style="margin-top: 25px; width: 100%"
              header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
            >
              <el-table-column label="广告" prop="name" align="center" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="类型" prop="type" align="center"></el-table-column>
              <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.state == 'enabled'" style="color: #2E8B57">启用</span>
                  <span v-if="scope.row.state == 'paused'" style="color: #8B0000">暂停</span>
                </template>
              </el-table-column>
              <el-table-column label="所属广告活动" prop="campaignName" align="center"></el-table-column>
              <el-table-column label="所属广告组" prop="adGroupName" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-link :underline="false" type="primary" @click="deleteTab(scope.$index)">移除</el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <!-- 执行条件 -->
          <el-row type="flex" justify="start" style="margin-top: 26px">
            <h4 style="margin-right: 8px; margin-top: 2px">执行条件</h4>
            <el-tooltip effect="dark" placement="top" content="满足执行条件的要求定时任务才会生效">
              <p class="el-icon-question" style="margin-right: 15px; margin-top: 2px"></p>
            </el-tooltip>
            <el-popover
              width="500"
              placement="right"
              trigger="click"
              v-model="advertiseExecutiveVisible"
            >
             <el-row v-for="(item, index4) in adCollection" :key="index4" style="margin-bottom: 15px">
               <el-select
                placeholder="执行条件"
                v-model="item.conditional"
                style="width: 150px; margin-right: 10px"
               >
                 <el-option
                  v-for="item in performsConditional"
                  :key="item.id"
                  :label="item.values"
                  :value="item.id"
                  @click.native="adToggleConditions(item, index4)"
                 ></el-option>
               </el-select>
               <!-- id为ctr和cvr和acos -->
               <el-select
                 v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false"
                 v-model="item.compare"
                 placeholder="请选择"
                 style="width: 150px; margin-right: 10px"
               >
                <el-option
                  v-for="item in JudgeConditions11"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                  @click.native="adChangeCompare(item, index4)"
                >
                </el-option>
               </el-select>
               <!-- id为非ctr和cvr和acos -->
               <el-select
                 v-show="item.conditional=='impressions'||
                 item.conditional=='clicks'||
                 item.conditional=='cost'||
                 item.conditional=='ordered'||
                 item.conditional=='sales'||
                 item.conditional==''?true:false"
                 v-model="item.compare"
                 placeholder="请选择"
                 style="width: 150px; margin-right: 10px"
               >
                <el-option
                  v-for="item in JudgeConditions22"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                  @click.native="adChangeCompare(item, index4)"
                >
                </el-option>
               </el-select>
               <el-input v-model="item.amount" placeholder="请输入" style="width: 150px; margin-right: 10px">
                 <i slot="suffix" v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false">
                    <img src="../../assets/common/percent.png" style="width: 26px; height: 26px; margin-top: 2px">
                 </i>
                 <i slot="suffix" v-show="item.conditional=='cost'||item.conditional=='sales'?true:fales">
                    <img src="../../assets/common/Jpy.png" style="width: 30px; height: 30px; margin-top: 1px; margin-left: 20px">
                 </i>
               </el-input>
               <p class="el-icon-close removeClass" @click="deleteEvent(index)"></p>
             </el-row>
             <el-row style="margin-top: 10px">
               <el-button 
                type="text" 
                icon="el-icon-plus" 
                @click="handleAppend"
                :disabled="adCollection.length==5?true:false"
               >添加</el-button>
             </el-row>
             <el-row type="flex" justify="end"> 
              <el-button size="mini" @click="advertiseExecutiveVisible = false">取消</el-button>
              <el-button size="mini" type="primary" @click="asyncClickConfirm">确定</el-button>
             </el-row>
             <el-link :underline="false" type="primary" slot="reference">添加条件</el-link>
            </el-popover>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-tag
             size="medium"
             closable
             :key="tag"
             v-for="(tag, index4) in dynamicTags3"
             @close="handleClose4(tag, index4)"
             style="margin-left: 15px"
            >{{tag}}
            </el-tag>
          </el-row>
          <el-row type="flex" justify="start" style="margin-top: 25px" v-show="adverTimeRange">
            <span style="margin-right: 15px; margin-top: 5px">执行条件的时间范围</span>
            <el-select
              size="mini"
              placeholder="请选择"
              v-model="adTimeRangeValue" 
              style="width: 120px"
            >
              <el-option 
               v-for="item in appTimeRangeList"
               :key="item.id"
               :label="item.name"
               :value="item.id"
              ></el-option>
            </el-select>
          </el-row>
          <!-- 执行频率 -->
          <el-row type="flex" justify="start" style="margin-top: 35px">
            <h4 style="margin-right: 8px; margin-top: 8px">执行频率</h4>
            <el-tooltip effect="dark" placement="top" content="请指定执行相应操作的执行频率">
              <span class="el-icon-question" style="margin-right: 15px; margin-top: 8px"></span>
            </el-tooltip>
            <el-select     
              size="small"
              style="width: 150px"
              v-model="performFrequency"
            >
             <el-option
              v-for="item in ExecutionFrequency"
              :key="item.id"
              :label="item.value"
              :value="item.id"
             ></el-option>
            </el-select>
            <!-- 一次 -->
            <el-date-picker
              v-show="performFrequency=='0'?true:false"
              v-model="adDateTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm" 
              placeholder="选择日期时间"
              style="width: 200px; margin-left: 10px"
              >
            </el-date-picker>
            <!-- 每日 -->
            <el-time-picker
              v-show="performFrequency=='1'?true:false"
              v-model="adTimePoint"
              placeholder="时间点"
              format="HH:mm"
              value-format="HH:mm"
              style="width: 160px; margin-left: 10px">
            </el-time-picker>
            <!-- 每周 -->
            <div v-show="performFrequency=='2'?true:false">
             <el-select 
               v-model="adExecuteDay"
               placeholder="请选择天"
               style="width: 110px; margin-left: 10px"
             >
               <el-option
                 v-for="item in oneWeekList"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
               ></el-option>
             </el-select>
             <el-time-picker
               v-model="adTimePoint"
               type="datetime"
               format="HH:mm"
               value-format="HH:mm"
               placeholder="时间点"
               style="width: 160px; margin-left: 10px"
               >
            </el-time-picker>
            </div>
            <!-- 每月 -->
            <div v-show="performFrequency=='3'?true:false">
               <el-select 
                v-model="adExecuteDay"
                placeholder="选择天"
                style="width: 120px; margin-left: 10px"
               >
                <el-option
                 v-for="item in oneMonthDate"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
                ></el-option>
               </el-select>
               <el-time-picker
                v-model="adTimePoint"
                type="datetime"
                format="HH:mm"
                value-format="HH:mm"
                placeholder="时间点"
                style="width: 180px; margin-left: 10px"
               >
               </el-time-picker>
            </div>
          </el-row>
          <!-- 执行通知 -->
          <el-row style="margin-top: 40px">
            <h4>执行通知</h4>
            <el-row v-show="adSendResult=='0'?false:true" style="margin: 18px 0 18px 50px">
              <span style="margin-right: 15px">通过邮件</span>
              <el-input v-model="adEmailAccount" clearable style="width: 40%" placeholder="请输入邮箱账号"></el-input>
            </el-row>
            <el-row style="margin-left: 50px">
              <span style="margin-right: 15px">发送结果</span>
              <el-select
                style="width: 40%"
                v-model="adSendResult"
                placeholder="请选择通知时间"
              >
                <el-option
                  v-for="item in sendResultsList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-row>
          </el-row>
        </div>
        <span slot="footer"> 
          <el-button @click="advertiseVisible = false">取 消</el-button>
          <el-button v-if="type4 == 1" type="primary" @click="adverConfirm(1)">确 认</el-button>
          <el-button v-if="type4 == 2" type="primary" @click="adverConfirm(2)">确 认</el-button>
        </span>
      </el-dialog>
      <!-- 选择执行对象(广告) -->
      <el-dialog
        width="40%"
        title="选择执行对象"
        :visible.sync="advertiseVisibleShow"
      >
        <el-row type="flex" justify="start">
          <el-select 
            clearable
            filterable 
            placeholder="广告活动"
            style="margin-right: 15px"
            @clear="adCampaignClear"
            v-model="pageDateObject.adCampaign"
          >
            <el-option
             v-for="item in adCampaignList"
             :key="item.id"
             :label="item.value"
             :value="item.id"
             @click.native="handle_select(item.id)"
            >
            <p>
              <span>({{item.type}})</span>-
              <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
              <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont  icon-zantingtingzhi"></span>
              <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont  icon-guidang"></span>
              <span>{{item.value}}</span>
            </p>
            </el-option>
          </el-select>
          <el-select
            clearable
            filterable
            placeholder="广告组"
            style="margin-right: 15px"
            v-model="pageDateObject.adGroup"
          >
            <el-option
              v-for="item in adGroupsList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            <p>
              <span>({{item.type}})</span>-
              <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
              <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont  icon-zantingtingzhi"></span>
              <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont  icon-guidang"></span>
              <span>{{item.value}}</span>
            </p>
            </el-option>
          </el-select>
          <el-input
            clearable
            placeholder="搜索广告" 
            v-model="pageDateObject.keyword" 
            style="margin-right: 18px; width: 180px"
          ></el-input>
          <el-button type="primary" icon="el-icon-search" @click="handleAdSearch">搜索</el-button>
        </el-row>
        <el-table
          border
          height="600"
          row-key="adId"
          ref="multipleTable4"
          :data="adTableData"
          style="margin-top: 20px; width: 100%"
          @selection-change="handleSelectionChange4"
        >
          <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
          <el-table-column label="ASIN" prop="name" align="center"></el-table-column>
          <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.state == 'enabled'" style="color: #2E8B57">启用</span>
              <span v-if="scope.row.state == 'paused'" style="color: #8B0000">暂停</span>
            </template>
          </el-table-column>
          <el-table-column label="所属广告活动" prop="campaignName" align="center"></el-table-column>
          <el-table-column label="所属广告组" prop="adGroupName" align="center"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange3"
          @current-change="handleCurrentChange3"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :total="total3"
          :page-size="pageDateObject.pageSize"
          :current-page="pageDateObject.current"
          style="margin-top: 25px; text-align: center"
        >
        </el-pagination>
        <span slot="footer">
          <el-button @click="advertiseVisibleShow = false">取 消</el-button>
          <el-button type="primary" @click="addAdConfirm">确 认</el-button>
        </span> 
      </el-dialog>

      <!-- 添加定时任务(关键词) -->
      <el-dialog
       width="40%"
       :visible.sync="keywordVisible"
      >
        <div slot="title">
          <span v-if="type5 == 1">添加定时任务(关键字)</span>
          <span v-if="type5 == 2">编辑定时任务(关键字)</span>
        </div>
        <el-form :label-position="labelPosition" :model="formLabelAlign" label-width="120px">
          <el-form-item label="任务名称">
            <el-input v-model="formLabelAlign.taskName" placeholder="请输入任务名称" style="width: 65%"></el-input>
          </el-form-item>
          <el-form-item label="为关键词创建">
            <el-select 
              style="width: 65%"
              placeholder="请选择" 
              :disabled="type5 == 2?true:false"
              v-model="formLabelAlign.createKeyword" 
            >
              <el-option label="启动关键词" value="enabled"></el-option>
              <el-option label="暂停关键词" value="paused"></el-option>
              <el-option label="更改关键词竞价" value="change"></el-option>
              <el-option label="分时调价" value="scheduled"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div v-show="formLabelAlign.createKeyword?true:false">
          <!-- 应用至关键词 -->
          <el-row type="flex" justify="start">
            <h4 style="margin-right: 6px">应用至关键词</h4>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                <span>您可以将任务对象应用到这一级别的所有广告系列广告活动、广告组、广告、关键词、商品定位和搜索词。</span><br/>
                <span>或者，您也可以在下面选中所需的复选框，仅将任务对象应用到选中的复选</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </el-row>
          <!-- 广告类型 -->
          <el-row style="margin: 26px 0 0 50px">
            <span style="margin-right: 20px">广告类型</span>
            <el-radio v-model="formLabelAlign.keywordsAdTypes" label="sp">SP广告</el-radio>
          </el-row>
          <!-- 应用范围 -->
          <el-row type="flex" justify="start" style="margin: 20px 0 0 50px">
            <span style="width: 75px;">应用范围</span>
            <el-radio label="ids" v-model="formLabelAlign.keywordApplicaRange">
              <span @click="onClickKeyword" style="color: #1E90FF">选择关键词</span>
            </el-radio>
          </el-row>
          <el-row v-show="formLabelAlign.keywordApplicaRange=='ids'?true:false">
            <el-table
              border
              height="300"
              :data="keywordTableList"
              style="margin-top: 25px; width: 100%"
              header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
            >
              <el-table-column label="关键词" prop="name" align="center" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="匹配类型" prop="matchType" align="center"></el-table-column>
              <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
              <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.state =='enabled'" style="color: #2E8B57">启用</span>
                  <span v-if="scope.row.state =='paused'" style="color: #8B0000">暂停</span>
                </template>
              </el-table-column>
              <el-table-column label="所属广告活动" prop="campaignName" align="center"></el-table-column>
              <el-table-column label="所属广告组" prop="adGroupName" align="center" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-link :underline="false" type="primary" @click="handleRemoveKeyword(scope.$index)">移除</el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-row> 
          <!-- 操作类型 -->
          <el-row 
            type="flex" 
            justify="start" 
            style="margin-top: 26px" 
            v-show="formLabelAlign.createKeyword=='change'?true:false"
          >
            <h4 style="width: 95px; margin-right: -20px; margin-top: 8px">操作类型</h4>
            <el-select
              placeholder="选择操作类型"
              v-model="keywordsOperaType"
              style="width: 130px; margin-right: 10px"
            >
              <el-option
                v-for="item in operationTypeList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-input 
              style="width: 150px" 
              placeholder="请输入金额" 
              v-model="keywordNumber" 
              v-show="keywordsOperaType=='fixed'?true:false"
            ></el-input>
            <div v-show="keywordsOperaType=='up'?true:false">
              <el-row type="flex" justify="start">
                <el-select style="width: 120px; margin-right: 8px" v-model="keywordMode">
                  <el-option label="按百分比提高" value="rate"></el-option>
                  <el-option label="按固定金额增加" value="fixed"></el-option>
                </el-select>
                <el-input style="width: 100px" v-model="keywordNumber"></el-input>
                <p style="margin: 8px 15px 0 15px">预算上限</p>
                <el-input style="width: 130px" v-model="keywordMaxLimit" placeholder="输入预算上限"></el-input>
              </el-row>
            </div>
            <div v-show="keywordsOperaType=='down'?true:false">
              <el-row type="flex" justify="start">
                <el-select style="width: 120px; margin-right: 8px" v-model="keywordMode">
                  <el-option label="按百分比降低" value="rate"></el-option>
                  <el-option label="按固定金额减少" value="fixed"></el-option>
                </el-select>
                <el-input style="width: 100px" v-model="keywordNumber"></el-input>
                <p style="margin: 8px 15px 0 15px">预算下限</p>
                <el-input style="width: 130px" v-model="keywordMinLimit" placeholder="输入预算下限"></el-input>
              </el-row>
            </div>
          </el-row>
          <!-- 分时调价 -->
          <el-row style="margin-top: 35px" v-show="formLabelAlign.createKeyword=='scheduled'?true:false">
            <el-row type="flex" justify="start">
              <h4 style="margin: 8px 8px 0 0">分时调价</h4>
              <el-radio v-model="a2" label="1" style="margin-top: 8px">设置基础竞价</el-radio>
              <el-input placeholder="输入基础竞价" size="small" v-model="timeDivisionBudget2" style="width: 150px"></el-input>
              <el-radio v-model="a2" label="2" style="margin: 8px 0 0 25px">使用关键词原始竞价为基础竞价</el-radio>
            </el-row> 
            <el-row style="margin-top: 25px">
              <span>说明：定时任务会按照【基础预算】上下调整对象的预算，不设置则选择调整对象的原始预算为基础预算</span>
            </el-row> 
            <el-row style="margin-top: 25px; margin-bottom: 25px">
              <el-select
                size="mini"
                style="width: 120px; margin-right: 20px"
                v-model="keywordDayType"
                @change="handleByKeyword"
              >
                <el-option label="按天" value="1"></el-option>
                <el-option label="按周" value="2"></el-option>
              </el-select>
              <el-tooltip effect="dark" placement="top" content="按天最多只能设置两个时间段">
                <el-button size="medium" type="text" @click="addKeywordTimeRange">添加时间段</el-button>
              </el-tooltip>
            </el-row>  
            <el-row v-for="(item, index) in addTimeRangeByKeyword" :key="index" style="margin-bottom: 10px">
              <el-select 
                v-if="keywordDayType =='2'?true:false"
                placeholder="请选择周几"
                v-model="item.severalWeeks" 
                style="width: 130px; margin-right: 15px" 
              >
                <el-option 
                  v-for="item in oneWeekList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-time-picker
                placeholder="开始时间"
                v-model="item.startTime"
                format='HH:mm'      
                value-format="HH:mm"
                :picker-options="{selectableRange:`00:00:00-${item.endTime ? item.endTime + ':00' : '23:59:59'}`}"
                style="width: 150px; margin-right: 15px"
              >
              </el-time-picker>
              <el-time-picker
                placeholder="结束时间"
                v-model="item.endTime"
                format='HH:mm'      
                value-format="HH:mm"
                :picker-options="{selectableRange:`${item.startTime ? item.startTime + ':00' : '00:00:00'}-23:59:59`}"
                style="width: 150px; margin-right: 15px"
              >
              </el-time-picker>
              <el-select placeholder="修改方式" v-model="item.modifyWay" style="width: 160px; margin-right: 15px">
                <el-option label="直接输入金额" value="0"></el-option>
                <el-option label="按百分比提高" value="1"></el-option>
                <el-option label="按百分比降低" value="2"></el-option>
                <el-option label="按固定金额增加" value="3"></el-option>
                <el-option label="按固定金额减少" value="4"></el-option>
              </el-select>
              <el-input v-model="item.inputAmount" style="width: 80px"></el-input>
              <span class="el-icon-close deleteButton" @click="handleRemoveBykey(index)"></span>
            </el-row>
          </el-row>
          <!-- 执行条件 -->
          <el-row type="flex" justify="start" style="margin-top: 26px" v-show="formLabelAlign.createKeyword=='scheduled'?false:true">
            <h4 style="margin-right: 8px; margin-top: 2px">执行条件</h4>
            <el-tooltip effect="dark" placement="top" content="满足执行条件的要求定时任务才会生效">
              <p class="el-icon-question" style="margin-right: 15px; margin-top: 2px"></p>
            </el-tooltip>
            <el-popover
              width="500"
              placement="right"
              trigger="click"
              v-model="keywordExecutionVisible"
            >
              <el-row v-for="(item, index5) in LoopListData" :key="index5" style="margin-bottom: 15px">
                <el-select
                  placeholder="执行条件"
                  v-model="item.conditional"
                  style="width: 150px; margin-right: 10px"
                >
                  <el-option
                    v-for="item in performsConditional"
                    :key="item.id"
                    :label="item.values"
                    :value="item.id"
                    @click.native="keywordToggleConditions(item, index5)"
                  ></el-option>
                </el-select>
                <!-- id为ctr和cvr和acos -->
                <el-select
                  v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false"
                  v-model="item.compare"
                  placeholder="请选择"
                  style="width: 150px; margin-right: 10px"
                >
                  <el-option
                   v-for="item in JudgeConditions11"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                   @click.native="keywordChangeCompare(item, index5)"
                  ></el-option>
                </el-select>
                <!-- id为非ctr和cvr和acos -->
                <el-select
                  v-show="item.conditional=='impressions'||
                  item.conditional=='clicks'||
                  item.conditional=='cost'||
                  item.conditional=='ordered'||
                  item.conditional=='sales'||
                  item.conditional==''?true:false"
                  v-model="item.compare"
                  placeholder="请选择"
                  style="width: 150px; margin-right: 10px"
                >
                  <el-option
                   v-for="item in JudgeConditions22"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                   @click.native="keywordChangeCompare(item, index5)"
                  ></el-option>
                </el-select>
                <el-input  v-model="item.amount" placeholder="请输入" style="width: 150px; margin-right: 10px">
                  <i slot="suffix" v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false">
                    <img src="../../assets/common/percent.png" style="width: 26px; height: 26px; margin-top: 2px">
                  </i>
                  <i slot="suffix" v-show="item.conditional=='cost'||item.conditional=='sales'?true:fales">
                    <img src="../../assets/common/Jpy.png" style="width: 30px; height: 30px; margin-top: 1px; margin-left: 20px">
                  </i>
                </el-input>
                <p class="el-icon-close removeClass" @click="deleteEvent(index)"></p>
              </el-row>
              <el-row style="margin-top: 10px">
                <el-button 
                 type="text" 
                 icon="el-icon-plus" 
                 @click="handleAddition"
                 :disabled="LoopListData.length == 5 ? true: false"
                >添加</el-button>
              </el-row>
              <el-row type="flex" justify="end">
                <el-button size="mini" @click="keywordExecutionVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="handleKeywordConfirm">确定</el-button>
              </el-row>
              <el-link :underline="false" type="primary" slot="reference">添加条件</el-link>
            </el-popover>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-tag
             size="medium"
             closable
             :key="tag"
             v-for="(tag, index5) in dynamicTags4"
             @close="handleClose5(tag, index5)"
             style="margin-left: 15px"
            >{{tag}}</el-tag>
          </el-row>
          <el-row type="flex" justify="start" style="margin-top: 25px" v-show="KeywordTimeRange2">
             <span style="margin-right: 15px; margin-top: 5px">执行条件的时间范围</span>
             <el-select 
               size="mini"
               placeholder="请选择"
               v-model="KeywordValue" 
               style="width: 120px"
             >
               <el-option 
                v-for="item in appTimeRangeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
               ></el-option>
             </el-select>
          </el-row>
          <!-- 执行频率 -->
          <el-row type="flex" justify="start" style="margin-top: 35px" v-show="formLabelAlign.createKeyword=='scheduled'?false:true">
            <h4 style="margin-right: 8px; margin-top: 8px">执行频率</h4>
            <el-tooltip effect="dark" placement="top" content="请指定执行相应操作的执行频率">
              <span class="el-icon-question" style="margin-right: 15px; margin-top: 8px"></span>
            </el-tooltip>
            <el-select
              size="small"
              style="width: 150px"
              v-model="keywordFrequency"
            >
              <el-option
                v-for="item in ExecutionFrequency"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 一次 -->
            <el-date-picker
              v-show="keywordFrequency=='0'?true:false"
              v-model="keywordTimePoint1"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm" 
              placeholder="选择日期时间"
              style="width: 160px; margin-left: 10px"
              >
            </el-date-picker>
            <!-- 每日 -->
            <el-time-picker
              v-show="keywordFrequency=='1'?true:false"
              v-model="chkeywordTimePoint"
              placeholder="时间点"
              format="HH:mm"
              value-format="HH:mm" 
              type="datetime"
              style="width: 160px; margin-left: 10px">
            </el-time-picker>
            <!-- 每周 -->
            <div v-show="keywordFrequency=='2'?true:false">
             <el-select 
               v-model="keywordExecuteDay"
               placeholder="请选择天"
               style="width: 110px; margin-left: 10px"
             >
               <el-option
                 v-for="item in oneWeekList"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
               ></el-option>
             </el-select>
             <el-time-picker
               v-model="chkeywordTimePoint"
               type="datetime"
               format="HH:mm"
               value-format="HH:mm"
               placeholder="时间点"
               style="width: 160px; margin-left: 10px"
               >
             </el-time-picker>
            </div>
            <!-- 每月 -->
            <div v-show="keywordFrequency=='3'?true:false">
             <el-select 
               v-model="keywordExecuteDay"
               placeholder="选择天"
               style="width: 120px; margin-left: 10px"
             >
               <el-option
                v-for="item in oneMonthDate"
                :key="item.id"
                :label="item.value"
                :value="item.id"
               ></el-option>
             </el-select>
             <el-time-picker
              v-model="chkeywordTimePoint"
              type="datetime"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="时间点"
              style="width: 180px; margin-left: 10px"
             ></el-time-picker>
            </div>
          </el-row>
          <!-- 执行通知 -->
          <el-row style="margin-top: 40px">
            <h4>执行通知</h4>
            <el-row v-show="keywordNoticeTime=='0'?false:true" style="margin: 18px 0 18px 50px">
              <span style="margin-right: 15px">通过邮件</span>
              <el-input v-model="keywordEmailAccount" clearable style="width: 40%" placeholder="请输入邮箱账号"></el-input>
            </el-row>
            <el-row style="margin-left: 50px">
              <span style="margin-right: 15px">发送结果</span>
              <el-select 
               style="width: 40%" 
               v-model="keywordNoticeTime" 
               placeholder="请选择通知时间"
              >
               <el-option
                 v-for="item in sendResultsList"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
               ></el-option>
              </el-select>
            </el-row>
          </el-row>
        </div>
        <span slot="footer"> 
          <el-button @click="keywordVisible = false">取 消</el-button>
          <el-button v-if="type5 == 1" type="primary" @click="keywordConfirm(1)">确 认</el-button>
          <el-button v-if="type5 == 2" type="primary" @click="keywordConfirm(2)">确 认</el-button>
        </span>
      </el-dialog>
      <!-- 选择执行对象(关键词) -->
      <el-dialog
        width="40%"
        title="选择执行对象"
        :visible.sync="keywordObjectVisible"
      >
         <el-row type="flex" justify="start">
          <el-select
            clearable
            filterable
            placeholder="广告活动"
            style="margin-right: 15px"
             @clear="adGroupsClear"
            v-model="pageDateKeyword.adCampaign"
          >
           <el-option
            v-for="item in adCampaignkeyword"
            :key="item.id"
            :label="item.value"
            :value="item.id"
            @click.native="handleSelectkeyword(item.id)"
           >
           <p>
             <span>({{item.type}})</span>-
             <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
             <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont  icon-zantingtingzhi"></span>
             <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont  icon-guidang"></span>
             <span>{{item.value}}</span>
           </p>
           </el-option>
          </el-select>
          <el-select
            clearable
            filterable
            placeholder="广告组"
            style="margin-right: 15px"
            v-model="pageDateKeyword.adGroup"
          >
            <el-option
             v-for="item in adGroupsArrList"
             :key="item.id"
             :label="item.value"
             :value="item.id"
            >
            <p>
              <span>({{item.type}})</span>-
              <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
              <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont  icon-zantingtingzhi"></span>
              <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont  icon-guidang"></span>
              <span>{{item.value}}</span>
            </p>
            </el-option>
          </el-select>
           <el-input
            clearable
            placeholder="搜索广告"
            v-model="pageDateKeyword.keyword"
            style="margin-right: 18px; width: 200px"
           ></el-input>
           <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleKeywordSearch"
           >搜索</el-button>
          </el-row>
          <el-table
            border
            height="600"
            row-key="keywordId"
            ref="multipleTable5"
            :data="KeywordTableData"
            style="margin-top: 35px; width: 100%"
            @selection-change="handleSelectionChange5"
          >
            <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
            <el-table-column label="关键词" prop="name" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="匹配类型" prop="matchType" align="center"></el-table-column>
            <el-table-column label="类型" prop="type" align="center"></el-table-column>
            <el-table-column label="状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.state == 'enabled'" style="color: #2E8B57">启用</span>
                <span v-if="scope.row.state == 'paused'" style="color: #8B0000">暂停</span>
              </template>
            </el-table-column>
            <el-table-column label="所属广告活动" prop="campaignName" align="center"></el-table-column>
            <el-table-column label="所属广告组" prop="adGroupName" align="center" :show-overflow-tooltip="true"></el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange4"
            @current-change="handleCurrentChange4"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[20, 30, 50, 100]"
            :total="total4"
            :page-size="pageDateKeyword.pageSize"
            :current-page="pageDateKeyword.current"
            style="margin-top: 25px; text-align: center"
          >
          </el-pagination>
          <span slot="footer">
            <el-button @click="keywordObjectVisible = false">取 消</el-button>
            <el-button type="primary" @click="addKeywordConfirm">确 认</el-button>
          </span>
      </el-dialog>

      <!-- 添加定时任务(商品定位) -->
      <el-dialog
        width="40%"
        :visible.sync="priductPositionVisible"
      >
        <div slot="title">
          <span v-if="type6 == 1">添加定时任务(商品定位)</span>
          <span v-if="type6 == 2">编辑定时任务(商品定位)</span>
        </div> 
        <el-form :label-position="labelPosition" :model="ruleForm" label-width="120px">
          <el-form-item label="任务名称">
            <el-input v-model="ruleForm.taskName" placeholder="请输入任务名称" style="width: 65%"></el-input>
          </el-form-item>
          <el-form-item label="为商品定位创建">
            <el-select
              style="width: 65%"
              placeholder="请选择"
              :disabled="type6 == 2?true:false"
              v-model="ruleForm.createPosition"
            >
             <el-option label="启动商品定位" value="enabled"></el-option>
             <el-option label="暂停商品定位" value="paused"></el-option>
             <el-option label="更改商品定位竞价" value="change"></el-option>
             <el-option label="分时调价" value="scheduled"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- 应用至商品定位 -->
        <div v-show="ruleForm.createPosition?true:false">
          <el-row type="flex" justify="start">
            <h4 style="margin-right: 6px">应用至商品定位</h4>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                <span>您可以将任务对象应用到这一级别的所有广告系列广告活动、广告组、广告、关键词、商品定位和搜索词。</span><br/>
                <span>或者，您也可以在下面选中所需的复选框，仅将任务对象应用到选中的复选</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </el-row>
          <!-- 广告类型 -->
          <el-row style="margin: 26px 0 0 50px">
            <span style="margin-right: 20px">广告类型</span>
            <el-radio v-model="ruleForm.adTypes" label="sp">SP广告</el-radio>
          </el-row>
          <!-- 应用范围 -->
          <el-row type="flex" justify="start" style="margin:10px 0 0 50px">
            <span style="width: 75px; margin-top: 16px">应用范围</span>
            <el-radio label="ids" v-model="goodsApplicaRange" style="margin-top: 15px;">
              <span @click="onClickGoodPosition" style="color: #1E90FF">按广告组选择商品定位</span>
            </el-radio>
          </el-row>
          <el-row v-show="goodsApplicaRange=='ids'?true:false"> 
            <el-table
             border
             height="300"
             :data="GoodPositionTable"
             style="margin-top: 25px; width: 100%"
             header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
            >
             <el-table-column label="商品定位" prop="name" align="center" :show-overflow-tooltip="true"></el-table-column>
             <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
             <el-table-column label="状态" align="center">
               <template slot-scope="scope">
                 <span v-if="scope.row.state =='enabled'" style="color: #2E8B57">启用</span>
                 <span v-if="scope.row.state =='paused'" style="color: #8B0000">暂停</span>
               </template>
             </el-table-column>
             <el-table-column label="所属广告活动" prop="campaignName" align="center"></el-table-column>
             <el-table-column label="所属广告组" prop="adGroupName" align="center"></el-table-column>
             <el-table-column label="操作" align="center">
               <template slot-scope="scope">
                 <el-link :underline="false" type="primary" @click="goodPositionRemove(scope.$index)">移除</el-link>
               </template>
             </el-table-column>
            </el-table>
          </el-row>
          <!-- 分时调价 -->
          <el-row style="margin-top: 35px" v-show="ruleForm.createPosition=='scheduled'?true:false">
            <el-row type="flex" justify="start">
              <h4 style="margin-right: 8px; margin-top: 8px">分时调价</h4>
              <el-radio v-model="a3" label="1" style="margin-top: 8px">设置基础预算</el-radio>
              <el-input size="mini" placeholder="输入基础竞价" v-model="timeDivisionBudget3" style="width: 150px"></el-input>
              <el-radio v-model="a3" label="2" style="margin-top: 8px; margin-left: 25px;">使用商品定位原始竞价为基础竞价</el-radio>
            </el-row>
            <el-row style="margin-top: 15px">
              <span>说明：定时任务会按照【基础预算】上下调整对象的预算，不设置则选择调整对象的原始预算为基础预算</span>
            </el-row>
            <el-row style="margin-top: 25px; margin-bottom: 25px">
              <el-select 
               size="mini"
               style="width: 120px; margin-right: 20px"
               v-model="goodsDayType"
               @change="handlePresetIn3"
              >
                <el-option label="按天" value="1"></el-option>
                <el-option label="按周" value="2"></el-option>
              </el-select>
              <el-button size="medium" type="text" @click="addTimeGoodsRange">添加时间段</el-button>
            </el-row>
            <el-row v-for="(item, index) in addTimeRangeByWeek6" :key="index" style="margin-bottom: 10px">
              <el-select
               v-if="goodsDayType == '2'"
               v-model="item.severalWeeks" 
               style="width: 130px; 
               margin-right: 15px" 
               placeholder="请选择周几"
              >
                <el-option
                 v-for="item in oneWeekList"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
                ></el-option>
              </el-select>
              <el-time-picker
                placeholder="开始时间"
                v-model="item.startTime"
                format='HH:mm'      
                value-format="HH:mm"
                :picker-options="{selectableRange:`00:00:00-${item.endTime ? item.endTime + ':00' : '23:59:59'}`}"
                style="width: 150px; margin-right: 15px"
              >
              </el-time-picker>
              <el-time-picker
                placeholder="结束时间"
                v-model="item.endTime"
                format='HH:mm'      
                value-format="HH:mm"
                :picker-options="{selectableRange:`${item.startTime ? item.startTime + ':00' : '00:00:00'}-23:59:59`}"
                style="width: 150px; margin-right: 15px"
              >
              </el-time-picker>
              <el-select placeholder="修改方式" v-model="item.modifyWay" style="width: 160px; margin-right: 15px">
                <el-option label="直接输入金额" value="0"></el-option>
                <el-option label="按百分比提高" value="1"></el-option>
                <el-option label="按百分比降低" value="2"></el-option>
                <el-option label="按固定金额增加" value="3"></el-option>
                <el-option label="按固定金额减少" value="4"></el-option>
              </el-select>
             <el-input v-model="item.inputAmount" style="width: 80px"></el-input>
             <span class="el-icon-close deleteButton" @click="handleRemoveGoods(index)"></span>
            </el-row>
          </el-row>
          <!-- 操作类型 -->
          <el-row type="flex" justify="start" style="margin-top: 26px;" v-show="ruleForm.createPosition=='change'?true:false">
            <h4 style="width: 95px; margin-right: -20px; margin-top: 8px">操作类型</h4>
            <el-select
              v-model="goodOperaType"
              placeholder="选择操作类型"
              style="width: 130px; margin-right: 10px"
            >
             <el-option
              v-for="item in operationTypeList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
             ></el-option>
            </el-select>
            <el-input 
              style="width: 150px" 
              placeholder="请输入金额" 
              v-model="goodsPositionNumber" 
              v-show="goodOperaType=='fixed'?true:false"></el-input>
            <div v-show="goodOperaType=='up'?true:false">
              <el-row type="flex" justify="start">
                <el-select style="width: 120px; margin-right: 8px" v-model="goodsMode">
                  <el-option label="按百分比提高" value="rate"></el-option>
                  <el-option label="按固定金额增加" value="fixed"></el-option>
                </el-select>
                <el-input style="width: 100px" v-model="goodsPositionNumber"></el-input>
                <p style="margin: 8px 15px 0 15px">预算上限</p>
                <el-input style="width: 130px" v-model="goodsMaxLimit" placeholder="输入预算上限"></el-input>
              </el-row>
            </div>
            <div v-show="goodOperaType=='down'?true:false">
              <el-row type="flex" justify="start">
                <el-select style="width: 120px; margin-right: 8px" v-model="goodsMode">
                  <el-option label="那百分比降低" value="rate"></el-option>
                  <el-option label="按固定金额减少" value="fixed"></el-option>
                </el-select>
                <el-input style="width: 100px" v-model="goodsPositionNumber"></el-input>
                <p style="margin: 8px 15px 0 15px">预算下限</p>
                <el-input style="width: 130px;" v-model="goodsMinLimit" placeholder="输入预算下限"></el-input>
              </el-row>
            </div>
          </el-row>
          <!-- 执行条件 -->
          <el-row type="flex" justify="start" style="margin-top: 26px" v-show="ruleForm.createPosition!='scheduled'?true:false">
            <h4 style="margin-right: 8px; margin-top: 2px">执行条件</h4>
            <el-tooltip effect="dark" placement="top" content="满足执行条件的要求定时任务才会生效">
              <p class="el-icon-question" style="margin-right: 15px; margin-top: 2px"></p>
            </el-tooltip>
            <el-popover
              width="500"
              placement="right"
              trigger="click"
              v-model="goodPositionVisible"
            >
             <el-row v-for="(item, index6) in goodPositionList" :key="index6" style="margin-bottom: 15px">
               <el-select
                 placeholder="执行条件"
                 v-model="item.conditional"
                 style="width: 150px; margin-right: 10px"
               >
                <el-option
                 v-for="item in performsConditional"
                 :key="item.id"
                 :label="item.values"
                 :value="item.id"
                 @click.native="toggleGoodPosition(item, index6)"
                ></el-option>
               </el-select>
               <!-- id为ctr和cvr和acos -->
               <el-select
                v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false"
                placeholder="请选择"
                v-model="item.compare"
                style="width: 150px; margin-right: 10px"
               >
                 <el-option
                  v-for="item in JudgeConditions11"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                  @click.native="toggleGoodPosition2(item, index6)"
                 ></el-option>
               </el-select>
               <!-- id为非ctr和cvr和acos -->
               <el-select
                v-show="item.conditional=='impressions'||
                item.conditional=='clicks'||
                item.conditional=='cost'||
                item.conditional=='ordered'||
                item.conditional=='sales'||
                item.conditional==''?true:false"
                placeholder="请选择"
                v-model="item.compare"
                style="width: 150px; margin-right: 10px"
               >
                 <el-option
                  v-for="item in JudgeConditions22"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                  @click.native="toggleGoodPosition2(item, index6)"
                 ></el-option>
               </el-select>
               <el-input v-model="item.amount" placeholder="请输入" style="width: 150px; margin-right: 10px">
                 <i slot="suffix" v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false">
                   <img src="../../assets/common/percent.png" style="width: 26px; height: 26px; margin-top: 2px">
                 </i>
                 <i slot="suffix" v-show="item.conditional=='cost'||item.conditional=='sales'?true:fales">
                   <img src="../../assets/common/Jpy.png" style="width: 30px; height: 30px; margin-top: 1px; margin-left: 20px">
                 </i>
               </el-input>
               <p class="el-icon-close removeClass" @click="deleteGoodsEvent(index6)"></p>
             </el-row>
             <el-row style="margin-top: 10px">
               <el-button type="text" icon="el-icon-plus" :disabled="goodPositionList.length == 5? true : false"
               @click="handleAddEvent">添加</el-button>
             </el-row>
             <el-row type="flex" justify="end">
               <el-button size="mini" @click="goodPositionVisible = false">取消</el-button>
              <el-button size="mini" type="primary" @click="handleConfirm3">确定</el-button>
             </el-row>
             <el-link :underline="false" type="primary" slot="reference">添加条件</el-link>
            </el-popover>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-tag
              size="medium"
              closable
              :key="tag"
              v-for="(tag, index6) in dynamicTags6"
              @close="handleClose6(tag, index6)"
              style="margin-left: 15px"
            >{{tag}}</el-tag>
          </el-row>
          <el-row type="flex" justify="start" style="margin-top: 25px" v-show="goodsPosiTimeRange">
            <span style="margin:5px 15px 0 0">执行条件的时间范围</span>
            <el-select
             size="mini"
             placeholder="请选择"
             style="width: 120px"
             v-model="goodsPositValue"
            >
              <el-option
                v-for="item in appTimeRangeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-row>
          <!-- 执行频率 -->
          <el-row type="flex" justify="start" style="margin-top: 35px" v-show="ruleForm.createPosition!='scheduled'?true:false">
            <h4 style="margin-right: 8px; margin-top: 8px">执行频率</h4>
            <el-tooltip effect="dark" placement="top" content="请指定执行相应操作的执行频率">
              <span class="el-icon-question" style="margin-right: 15px; margin-top: 8px"></span>
            </el-tooltip> 
            <el-select
              size="small"
              v-model="frequencyType"
              style="width: 150px"
            >
              <el-option
                v-for="item in ExecutionFrequency"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 一次 -->
            <el-date-picker
              v-show="frequencyType=='0'?true:false"
              v-model="goodsTimePoint1"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="选择日期时间"
              style="width: 200px; margin-left: 10px"
              >
            </el-date-picker>
            <!-- 每日 -->
            <el-time-picker
              v-show="frequencyType=='1'?true:false"
              v-model="goodsPosiTimePoint"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="时间点"
              style="width: 160px; margin-left: 10px">
            </el-time-picker>
            <!-- 每周 -->
            <div v-show="frequencyType=='2'?true:false">
            <el-select 
              v-model="goodExecuteDay"
              placeholder="请选择天"
              style="width: 110px; margin-left: 10px"
            >
              <el-option
                v-for="item in oneWeekList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-time-picker
              v-model="goodsPosiTimePoint"
              type="datetime"
              placeholder="时间点"
              format="HH:mm"
              value-format="HH:mm"
              style="width: 160px; margin-left: 10px"
              >
            </el-time-picker>
            </div>
            <!-- 每月 -->
            <el-row v-show="frequencyType=='3'?true:false">
            <el-select 
              v-model="goodExecuteDay"
              placeholder="选择天"
              style="width: 120px; margin-left: 10px"
            >
              <el-option
               v-for="item in oneMonthDate"
               :key="item.id"
               :label="item.value"
               :value="item.id"
              ></el-option>
            </el-select>
            <el-time-picker
             v-model="goodsPosiTimePoint"
             type="datetime"
             format="HH:mm"
             value-format="HH:mm"
             placeholder="时间点"
             style="width: 180px; margin-left: 10px"
            >
            </el-time-picker>
            </el-row>
          </el-row>
          <!-- 执行通知 -->
          <el-row style="margin-top: 40px">
            <h4>执行通知</h4>
            <el-row v-show="goodNoticeTime=='0'?false:true" style="margin-top: 18px; margin-bottom: 18px; margin-left: 50px">
              <span style="margin-right: 15px">通过邮件</span>
              <el-input v-model="goodEmailAccount" clearable style="width: 40%" placeholder="请输入邮箱账号"></el-input>
            </el-row>
            <el-row style="margin-left: 50px">
              <span style="margin-right: 15px">发送结果</span>
              <el-select
                style="width: 40%"
                v-model="goodNoticeTime"
                placeholder="请选择通知时间"
              >
               <el-option
                v-for="item in sendResultsList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
               ></el-option>
              </el-select>
            </el-row>
          </el-row>
        </div>
        <span slot="footer">
          <el-button @click="priductPositionVisible = false">取 消</el-button>
          <el-button v-if="type6 == 1" type="primary" @click="goodPositionConfirm(1)">确 定</el-button>
          <el-button v-if="type6 == 2" type="primary" @click="goodPositionConfirm(2)">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 选择执行对象(商品定位) -->
      <el-dialog
        width="40%"
        title="选择执行对象"
        :visible.sync="goodObjectVisible"
      >
        <el-row type="flex" justify="start">
          <el-select
           clearable
           filterable 
           placeholder="广告活动"
           style="margin-right: 15px"
           @clear="adGoodsClear"
           v-model="pageDateGoods.adCampaign"
          >
            <el-option
             v-for="item in adCampaignGoods"
             :key="item.id"
             :label="item.value"
             :value="item.id"
             @click.native="handleSelectGoods(item.id)"
            >
            <p>
              <span>({{item.type}})</span>-
              <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
              <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont  icon-zantingtingzhi"></span>
              <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont  icon-guidang"></span>
              <span>{{item.value}}</span>
            </p>
            </el-option>
          </el-select>
          <el-select
           clearable
           filterable
           placeholder="广告组"
           style="margin-right: 15px"
           v-model="pageDateGoods.adGroup"
          >
            <el-option
              v-for="item in adGroupsGoodsArrList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            <p>
              <span>({{item.type}})</span>-
              <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
              <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont  icon-zantingtingzhi"></span>
              <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont  icon-guidang"></span>
              <span>{{item.value}}</span>
            </p>
            </el-option>
          </el-select>
          <el-input 
           clearable
           placeholder="搜索商品定位"
           v-model="pageDateGoods.keyword" 
           style="margin-right: 18px; width: 200px"
          ></el-input>
          <el-button 
            type="primary" 
            icon="el-icon-search" 
            @click="goodPositionSearch"
          >搜索</el-button>
        </el-row>
        <el-table
         border
         height="600"
         row-key="targetId"
         ref="multipleTable6"
         :data="goodsTableList"
         style="margin-top: 35px; width: 100%"
         @selection-change="handleSelectionChange6"
        >
          <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
          <el-table-column label="商品定位" prop="name" align="center"></el-table-column>
          <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.state == 'enabled'" style="color: #2E8B57">启用</span>
              <span v-if="scope.row.state == 'paused'" style="color: #8B0000">暂停</span>
            </template>
          </el-table-column>
          <el-table-column label="所属广告活动" prop="campaignName" align="center"></el-table-column>
          <el-table-column label="所属广告组" prop="adGroupName" align="center"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange5"
          @current-change="handleCurrentChange5"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :total="total5"
          :page-size="pageDateGoods.pageSize"
          :current-page="pageDateGoods.current"
          style="margin-top: 25px; text-align: center"
        >
      </el-pagination>
        <span slot="footer">
          <el-button @click="goodObjectVisible = false">取 消</el-button>
          <el-button type="primary" @click="addGoodsConfirm">确 认</el-button>
        </span>
      </el-dialog>

      <!-- 添加定时任务(自动投放) --> 
      <el-dialog
        width="40%"
        :visible.sync="targetVisible"
      >
       <div slot="title">
         <span v-if="type7 == 1">添加定时任务(自动投放)</span>
         <span v-if="type7 == 2">编辑定时任务(自动投放)</span>
       </div>
       <el-form :label-position="labelPosition" :model="dynamicdateForm" label-width="120px">
         <el-form-item label="任务名称">
           <el-input v-model="dynamicdateForm.taskName" placeholder="请输入任务名称" style="width: 65%"></el-input>
         </el-form-item>
         <el-form-item label="为自动投放创建">
           <el-select 
            placeholder="请选择" 
            style="width: 65%"
            :disabled="type7 == 2?true:false"
            v-model="dynamicdateForm.autoTarget"
           >
             <el-option label="启动自动投放" value="enabled"></el-option>
             <el-option label="暂停自动投放" value="paused"></el-option>
             <el-option label="更改自动投放竞价" value="change"></el-option>
             <el-option label="分时调价" value="scheduled"></el-option>
           </el-select>
         </el-form-item>
       </el-form>
       <div v-show="dynamicdateForm.autoTarget?true:false">
         <!-- 应用至广告活动 -->
         <el-row type="flex" justify="start">
           <h4 style="margin-right: 6px">应用至广告活动</h4>
           <el-tooltip effect="dark" placement="top">
             <div slot="content">
               <span>您可以将任务对象应用到这一级别的所有广告系列广告活动、广告组、广告、关键词、商品定位和搜索词。</span>
               <span>或者，您也可以在下面选中所需的复选框，仅将任务对象应用到选中的复选</span>
             </div>
             <span class="el-icon-question"></span>
           </el-tooltip>
         </el-row>
         <!-- 广告类型 -->
         <el-row style="margin:26px 0 0 50px">
           <span style="margin-right: 20px">广告类型</span>
           <el-radio v-model="dynamicdateForm.autoTargetTypes" label="sp">SP广告</el-radio>
         </el-row>
         <!-- 应用范围 -->
         <el-row type="flex" justify="start" style="margin: 10px 0 0 50px">
           <span style="width: 75px; margin-top: 15px">应用范围</span>
           <el-radio-group v-model="dynamicdateForm.autoApplicaRange">
             <el-radio label="enabled">所有已启动的自动投放</el-radio>
             <el-radio label="all">所有已启动和已暂停的自动投放</el-radio>
             <el-radio label="ids" style="margin-top: 15px">
               <span @click="onClickAutoTarget" style="color: #1E90FF">选择自动投放</span>
             </el-radio>
           </el-radio-group>
         </el-row>
         <el-row v-show="dynamicdateForm.autoApplicaRange=='ids'?true:false">
           <el-table
            border
            height="300"
            :data="autoTableList"
            style="margin-top: 25px; width: 100%"
            header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
           >
            <el-table-column label="自动投放" prop="name" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
            <el-table-column label="状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.state =='enabled'" style="color: #2E8B57">启用</span>
                <span v-if="scope.row.state =='paused'" style="color: #8B0000">暂停</span>
              </template>
            </el-table-column>
            <el-table-column label="所属广告活动" prop="campaignName" align="center"></el-table-column>
            <el-table-column label="所属广告组" prop="adGroupName" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-link type="primary" @click="autoTargetRemove(scope.$index)">移除</el-link>
              </template>
            </el-table-column>
           </el-table>
         </el-row>
         <!-- 分时调价 -->
         <el-row style="margin-top: 35px"  v-show="dynamicdateForm.autoTarget=='scheduled'?true:false">
           <el-row type="flex" justify="start">
             <h4 style="margin: 8px 8px 0 0">分时调预算</h4>
             <el-radio v-model="a4" label="1" style="margin-top: 8px">设置基础竞价</el-radio>
             <el-input size="small" placeholder="输入基础预算" style="width: 150px" v-model="timeDivisionTarget"></el-input>
             <el-radio v-model="a4" label="2" style="margin: 8px 0 0 25px">使用自动投放原始竞价为基础竞价</el-radio>
           </el-row>
           <el-row style="margin-top: 30px">
             <span>说明：定时任务会按照【基础预算】上下调整对象的预算，不设置则选择调整对象的原始预算为基础预算</span>
           </el-row>
           <el-row style="margin-top: 25px; margin-bottom: 25px">
             <el-select
              size="mini" 
              style="width: 120px; margin-right: 20px"
              v-model="autoTargetdayType"
              @change="handlePresetIn2"
             >  
              <el-option label="按天" value="1"></el-option>
              <el-option label="按周" value="2"></el-option>
             </el-select>
             <el-button size="medium" type="text" @click="addTimeTargetRange">添加时间段</el-button>
           </el-row>
           <el-row v-for="(item, index) in addTimeRangeByWeek7" :key="index" style="margin-bottom: 10px">
             <el-select
              placeholder="请选择周几"
              v-model="item.severalWeeks" 
              v-if="autoTargetdayType == '2'"
              style="width: 130px; margin-right: 15px" 
             >
               <el-option
                v-for="item in oneWeekList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
               ></el-option>
             </el-select>
            <el-time-picker
              placeholder="开始时间"
              v-model="item.startTime"
              format='HH:mm'      
              value-format="HH:mm"
              :picker-options="{selectableRange:`00:00:00-${item.endTime ? item.endTime + ':00' : '23:59:59'}`}"
              style="width: 150px; margin-right: 15px"
            >
            </el-time-picker>
            <el-time-picker
              placeholder="结束时间"
              v-model="item.endTime"
              format='HH:mm'      
              value-format="HH:mm"
              :picker-options="{selectableRange:`${item.startTime ? item.startTime + ':00' : '00:00:00'}-23:59:59`}"
              style="width: 150px; margin-right: 15px"
            >
            </el-time-picker>
            <el-select placeholder="修改方式" v-model="item.modifyWay" style="width: 160px; margin-right: 15px">
              <el-option label="直接输入金额" value="0"></el-option>
              <el-option label="按百分比提高" value="1"></el-option>
              <el-option label="按百分比降低" value="2"></el-option>
              <el-option label="按固定金额增加" value="3"></el-option>
              <el-option label="按固定金额减少" value="4"></el-option>
            </el-select>
            <el-input v-model.number="item.inputAmount" style="width: 80px"></el-input>
            <span class="el-icon-close deleteButton" @click="handleDeleteByTarget(index)"></span>
           </el-row>
         </el-row>
         <!-- 操作类型 -->
         <el-row type="flex" justify="start" style="margin-top: 26px" v-show="dynamicdateForm.autoTarget=='change'?true:false">
           <h4 style="width: 95px; margin-right: -20px; margin-top: 8px">操作类型</h4>
           <el-select
            v-model="autoTargetType"
            placeholder="请选择操作类型"
            style="width: 150px; margin-right: 10px"
           >
             <el-option
              v-for="item in operationTypeList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
             ></el-option>
           </el-select>
           <el-input 
             style="width: 150px" 
             placeholder="请输入金额" 
             v-model="autoTargetAmount"
             v-show="autoTargetType=='fixed'?true:false"
           ></el-input>
           <div v-show="autoTargetType=='up'?true:false">
             <el-row type="flex" justify="start">
               <el-select style="width: 150px; margin-right: 8px" v-model="autoTargetMode">
                 <el-option label="按百分比提高" value="rate"></el-option>
                 <el-option label="按固定金额增加" value="fixed"></el-option>
               </el-select>
               <el-input style="width: 100px" v-model="autoTargetAmount"></el-input>
               <p style="margin: 8px 15px 0 15px">预算上限</p>
               <el-input style="width: 100px" v-model="autoTargetMaxLimit" placeholder="请输入预算上限"></el-input>
             </el-row>
           </div>
           <div v-show="autoTargetType=='down'?true:false">
             <el-row type="flex" justify="start">
               <el-select style="width: 150px; margin-right: 8px" v-model="autoTargetMode">
                 <el-option label="按百分比降低" value="rate"></el-option>
                 <el-option label="按固定金额减少" value="fixed"></el-option>
               </el-select>
               <el-input style="width: 100px" v-model="autoTargetAmount"></el-input>
               <p style="margin: 8px 15px 0 15px">预算下限</p>
               <el-input style="width: 100px" v-model="autoTargetMinLimit" placeholder="请输入预算下限"></el-input>
             </el-row>
           </div> 
         </el-row>
         <!-- 执行条件 -->
         <el-row type="flex" justify="start" style="margin-top: 26px" v-show="dynamicdateForm.autoTarget!='scheduled'?true:false">
           <h4 style="margin: 2px 8px 0 0">执行条件</h4>
           <el-tooltip effect="dark" placement="top" content="满足执行条件的要求定时任务才会生效">
             <p class="el-icon-question" style="margin: 2px 15px 0 0"></p>
           </el-tooltip>
           <el-popover
             width="500"
             placement="right"
             trigger="click"
             v-model="autoTargetVisible"
           >  
            <el-row v-for="(item,index7) in autoTargetList" :key="index7" style="margin-bottom: 15px">
              <el-select
               placeholder="执行条件"
               v-model="item.conditional"
               style="width: 150px; margin-right: 10px"
              >
                <el-option
                  v-for="item in performsConditional"
                  :key="item.id"
                  :label="item.values"
                  :value="item.id"
                  @click.native="handleAutoTargetToggle(item, index7)"
                ></el-option>
              </el-select>
              <!-- id为ctr和cvr和acos -->
              <el-select
               v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false"
               placeholder="请选择"
               v-model="item.compare"
               style="width: 150px; margin-right: 10px"
              >
                <el-option
                 v-for="item in JudgeConditions11"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
                 @click.native="handleAutoTargetToggle2(item, index7)"
                ></el-option>
              </el-select>
              <!-- id为非ctr和cvr和acos -->
              <el-select
               v-show="item.conditional=='impressions'||
               item.conditional=='clicks'||
               item.conditional=='cost'||
               item.conditional=='ordered'||
               item.conditional=='sales'||
               item.conditional==''?true:false"
               placeholder="请选择"
               v-model="item.compare"
               style="width: 150px; margin-right: 10px"
              >
                <el-option
                 v-for="item in JudgeConditions22"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
                 @click.native="handleAutoTargetToggle2(item, index7)"
                ></el-option>
              </el-select>
              <el-input v-model="item.amount" placeholder="请输入" style="width: 150px; margin-right: 10px">
                <i slot="suffix" v-show="item.conditional=='ctr'||item.conditional=='cvr'||item.conditional=='acos'?true:false">
                  <img src="../../assets/common/percent.png" style="width: 26px; height: 26px; margin-top: 2px">
                </i>
                <i slot="suffix" v-show="item.conditional=='cost'||item.conditional=='sales'?true:fales">
                  <img src="../../assets/common/Jpy.png" style="width: 30px; height: 30px; margin-top: 1px; margin-left: 20px">
                </i>
              </el-input>
              <p class="el-icon-close removeClass" @click="handleTargetEvent(index7)"></p>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-button type="text" icon="el-icon-plus" :disabled="autoTargetList.length == 5? true : false" @click="handleIncrease">添加</el-button>
            </el-row>
            <el-row type="flex" justify="end">
              <el-button size="mini" @click="autoTargetVisible = false">取消</el-button>
              <el-button size="mini" type="primary" @click="handleConfirm2">确定</el-button>
            </el-row>
            <el-link :underline="false" type="primary" slot="reference">添加条件</el-link>
           </el-popover>
         </el-row>
         <el-row style="margin-top: 20px">
           <el-tag
            size="medium"
            closable
            :key="tag"
            v-for="(tag, index7) in dynamicTags5"
            @close="handleClose7(tag, index7)"
            style="margin-left: 15px"
           >{{tag}}
           </el-tag>
         </el-row>
         <el-row type="flex" justify="start" style="margin-top: 25px" v-show="autoTargeteTimeRange">
           <span style="margin: 5px 15px 0 0">执行条件的时间范围</span>
            <el-select 
              size="mini"
              placeholder="请选择"
              style="width: 120px"
              v-model="autoTargeteValue" 
            >
              <el-option 
               v-for="item in appTimeRangeList"
               :key="item.id"
               :label="item.name"
               :value="item.id"
              ></el-option>
            </el-select>
         </el-row>
         <!-- 执行频率 -->
         <el-row type="flex" justify="start" style="margin-top: 35px" v-show="dynamicdateForm.autoTarget!='scheduled'?true:false">
           <h4 style="margin: 8px 8px 0 0">执行频率</h4>
           <el-tooltip effect="dark" placement="top" content="请指定执行操作的执行频率">
            <span class="el-icon-question" style="margin-right: 15px; margin-top: 8px"></span>
           </el-tooltip>
           <el-select
             size="small"
             style="width: 150px"
             v-model="autoTargetFrequency"
           >
            <el-option
              v-for="item in ExecutionFrequency"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
           </el-select>
           <!-- 一次 -->
           <el-date-picker
             v-show="autoTargetFrequency=='0'?true:false"
             v-model="targetTimePoint1"
             type="datetime"
             format="yyyy-MM-dd HH:mm"
             value-format="yyyy-MM-dd HH:mm"
             placeholder="选择日期时间"
             style="width: 200px; margin-left: 10px"
             >
           </el-date-picker>
           <!-- 每日 -->
           <el-time-picker
             v-show="autoTargetFrequency=='1'?true:false"
             v-model="autargetTimePoint"
             placeholder="时间点"
             type="datetime"
             format="HH:mm"
             value-format="HH:mm"
             style="width: 160px; margin-left: 10px">
           </el-time-picker>
           <!-- 每周 -->
           <div v-show="autoTargetFrequency=='2'?true:false">
             <el-select
              v-model="autoExecuteDay"
              placeholder="请选择天"
              style="width: 110px; margin-left: 10px"
             >
              <el-option
                v-for="item in oneWeekList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
             </el-select>
             <el-time-picker
               v-model="autargetTimePoint"
               type="datetime"
               format="HH:mm"
               value-format="HH:mm"
               placeholder="时间点"
               style="width: 160px; margin-left: 10px"
               >
             </el-time-picker>
           </div>
           <!-- 每月 -->
           <div v-show="autoTargetFrequency=='3'?true:false">
             <el-select 
               v-model="autoExecuteDay"
               placeholder="选择天"
               style="width: 120px; margin-left: 10px"
             >
               <el-option
                v-for="item in oneMonthDate"
                :key="item.id"
                :label="item.value"
                :value="item.id"
               ></el-option>
             </el-select>
             <el-time-picker
              v-model="autargetTimePoint"
              type="datetime"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="时间点"
              style="width: 180px; margin-left: 10px"
             >
             </el-time-picker>
           </div> 
         </el-row>
         <!-- 执行通知 -->
         <el-row style="margin-top: 40px">
           <h4>执行通知</h4>
           <el-row style="margin: 18px 0px 18px 50px" v-show="autoNoticeTime=='0'?false:true">
             <span style="margin-right:18px;">通过邮件</span>
             <el-input clearable v-model="autoEmailAccount" style="width: 40%" placeholder="请输入邮箱账号"></el-input>
           </el-row>
           <el-row style="margin-left: 50px">
             <span style="margin-right:18px">发送结果</span>
             <el-select
              style="width: 40%"
              placeholder="请选择通知时间"
              v-model="autoNoticeTime"
             >
              <el-option
                v-for="item in sendResultsList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
             </el-select>
           </el-row>
         </el-row>
       </div>
       <span slot="footer">
         <el-button @click="targetVisible = false">取 消</el-button>
         <el-button v-if="type7 == 1" type="primary" @click="addAutoTarget(1)">确 定</el-button>
         <el-button v-if="type7 == 2" type="primary" @click="addAutoTarget(2)">确 定</el-button>
       </span>
      </el-dialog>
      <!-- 选择执行对象(自动投放) -->
      <el-dialog
        width="40%"
        title="选择执行对象"
        :visible.sync="targetsObjectVisible"
      > 
        <el-row type="flex" justify="start">
          <el-select
            filterable
            placeholder="广告活动"
            style="margin-right: 15px"
            @clear="adTargetClear"
            v-model="pageDateTargets.adCampaign"
          >
            <el-option
              v-for="item in adCampaignTarget"
              :key="item.id"
              :label="item.value"
              :value="item.id"
              @click.native="handleSelectTarget(item.id)"
            >
            <p>
              <span>({{item.type}})</span>-
              <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
              <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont  icon-zantingtingzhi"></span>
              <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont  icon-guidang"></span>
              <span>{{item.value}}</span>
            </p>
            </el-option>
          </el-select>
          <el-select
            clearable
            filterable
            placeholder="广告组"
            style="margin-right: 15px"
            v-model="pageDateTargets.adGroup"
          >
            <el-option
              v-for="item in adTargetArrList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            <p>
              <span>({{item.type}})</span>-
              <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
              <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont  icon-zantingtingzhi"></span>
              <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont  icon-guidang"></span>
              <span>{{item.value}}</span>
            </p>
            </el-option>
          </el-select>
          <el-button icon="el-icon-search" type="primary" @click="autoTargetSearch">搜索</el-button>
        </el-row>
        <el-table
          border
          height="600"
          row-key="targetId"
          ref="multipleTable7"
          :data="autoTargetTablList"
          style="margin-top: 35px; width: 100%"
          @selection-change="handleSelectionChange7"
        >
          <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
          <el-table-column label="自动投放" prop="name" align="center"></el-table-column>
          <el-table-column label="广告类型" prop="type" align="center"></el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.state =='enabled'" style="color: #2E8B57">启用</span>
              <span v-if="scope.row.state =='paused'" style="color: #8B0000">暂停</span>
            </template>
          </el-table-column>
          <el-table-column label="所属广告活动" prop="campaignName" align="center"></el-table-column>
          <el-table-column label="所属广告组" prop="adGroupName" align="center"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange6"
          @current-change="handleCurrentChange6"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :total="total6"
          :page-size="pageDateTargets.pageSize"
          :current-page="pageDateTargets.current"
          style="margin-top: 25px; text-align: center"
        >
        </el-pagination>
        <span slot="footer" class="dialog-footer"> 
          <el-button @click="targetsObjectVisible = false">取 消</el-button>
          <el-button type="primary" @click="addTargetConfirm">确 认</el-button>
        </span>
      </el-dialog>

      <!-- 广告操作记录 -->
      <el-dialog width="70%" :visible.sync="operationVisible">
       <div slot="title" class="header-title">
         <span>{{dialogTitle}}操作记录</span><br />
       </div>
       <el-table :data="adOperationList">
         <el-table-column prop="campaignName" label="广告活动" align="center"></el-table-column>
         <el-table-column prop="groupName" label="广告组" align="center"></el-table-column>
         <el-table-column prop="executeTime" label="操作时间" align="center"></el-table-column>
         <el-table-column prop="" label="操作人" align="center"></el-table-column>
         <el-table-column prop="executeType" label="执行" align="center"></el-table-column>
         <el-table-column prop="adType" label="广告类型" align="cenetr"></el-table-column>
         <el-table-column prop="operateObject" label="操作对象" align="cenetr"></el-table-column>
         <el-table-column prop="operateType" label="操作类型" align="cenetr"></el-table-column>
         <el-table-column prop="objectDetail" label="对象详情" align="cenetr"></el-table-column>
         <el-table-column prop="beforeChange" label="操作前" align="cenetr"></el-table-column>
         <el-table-column prop="afterChange" label="操作后" align="cenetr"></el-table-column>
         <el-table-column prop="result" label="操作结果" align="cenetr"></el-table-column>
       </el-table>
       <el-pagination
         @size-change="handleSizeOprecords"
         @current-change="handleCurrentOprecords"
         layout="total, sizes, prev, pager, next, jumper"
         :page-sizes="[20, 30, 50, 100]"
         :total="totalOprecords"
         :page-size="pageInfoOprecords.pageSize"
         :current-page="pageInfoOprecords.current"
         style="margin-top: 25px; text-align: center"
       ></el-pagination>
      </el-dialog>
      <!-- 执行结果 -->
      <el-dialog :title="executionTitle" :visible.sync="executionVisible">
        <el-row style="margin-bottom: 15px">
          <h4>SP广告系列,{{executionTitle}}{{taskRules}}</h4>
        </el-row>
        <el-row type="flex" justify="start">
          <span style="margin-right: 10px">创建人:</span><h4>超级管理员</h4>
        </el-row>
        <el-tabs v-model="activeValue" @tab-click="handleClick" style="margin-top: 30px">
          <el-tab-pane name="totalNumber">
            <span slot="label">执行数量{{performNumber}}</span>
            <el-table height="600" :data="sumNumberTableList" style="margin-top: 15px">
              <el-table-column label="广告对象" prop="adObject" align="center"></el-table-column>
              <el-table-column label="更改" prop="type" align="center"></el-table-column>
              <el-table-column label="状态" prop="result" align="center"></el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeAmount"
              @current-change="handleCurrentAmount"
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[20, 30, 50, 100]"
              :total="totalAmount"
              :page-size="pageInfoAmount.pageSize"
              :current-page="pageInfoAmount.current"
              style="margin-top: 25px; text-align: center"
            ></el-pagination>
          </el-tab-pane>
          <el-tab-pane name="successNumber">
            <span slot="label">执行成功{{performSuccessNumber}}</span>
            <el-table height="600" :data="successTableList" style="margin-top: 15px">
              <el-table-column label="广告对象" prop="adObject" align="center"></el-table-column>
              <el-table-column label="更改" prop="type" align="center"></el-table-column>
              <el-table-column label="状态" prop="result" align="center"></el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeSuccess"
              @current-change="handleCurrentSuccess"
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[20, 30, 50, 100]"
              :total="totalSuccess"
              :page-size="pageInfoSuccess.pageSize"
              :current-page="pageInfoSuccess.current"
              style="margin-top: 25px; text-align: center"
            ></el-pagination>
          </el-tab-pane>
          <el-tab-pane name="defeatedNumber">
            <span slot="label">执行失败{{performNumber-performSuccessNumber}}</span>
            <el-table height="600" :data="defeatedTableList" style="margin-top: 15px">
              <el-table-column label="广告对象" prop="adObject" align="center"></el-table-column>
              <el-table-column label="更改" prop="type" align="center"></el-table-column>
              <el-table-column label="状态" prop="result" align="center"></el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeDefeated"
              @current-change="handleCurrentDefeated"
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[20, 30, 50, 100]"
              :total="totalDefeated"
              :page-size="pageInfoDefeated.pageSize"
              :current-page="pageInfoDefeated.current"
              style="margin-top: 25px; text-align: center"
            ></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
    </div>  
  </div>
</template>

<script>
import {
  pageInit,          // 查询初始化 
  getListPage,       // 查询分页
  listObjectByType,  // 查询应用对象
  listObjectInit,    // 查询应用对象初始化
  addScheduled,      //  新增
  homePageInit,      // 首页查询初始化
  updateStatusById,  // 修改任务状态
  getEditInit,       // 编辑初始化
  editScheduled,     // 编辑
  getRecordListPage, // 执行记录查询
  getOpRecorddetail,
  getresultListPage,
}from "@/api/Advertising/modifyPrice.js";
import AdvertiseDialog from '@/components/TimeStrategy/AdvertiseDialog.vue'
import TimeQuantum from '@/components/TimeQuantum'
import ShopsAndSites from "@/components/ShopsAndSites.vue";
import LoginVue from '../Login.vue';
import roleVue from '../BasicManagement/role.vue';
export default {
  components: {
    TimeQuantum,
    AdvertiseDialog,  // 广告位弹框
    ShopsAndSites
  },
   data() {
     return {
       activeName: 'timeTask',
       activeValue: "totalNumber",
       executeType1: "",  // 广告活动(操作类型)
       type1: 0,  // type1 为 1 是添加操作 type1 为 2 是修改操作
       type2: 0,  // type2 为 1 是添加操作 type2 为 2 是修改操作
       type4: 0,  // type4 为 1 是添加操作 type4 为 2 是修改操作
       type5: 0,  // type5 为 1 是添加操作 type5 为 2 是修改操作
       type6: 0,  // type6 为 1 是添加操作 type6 为 2 是修改操作  
       type7: 0,  // type7 为 1 是添加操作 type7 为 2 是修改操作 
       total: 0,  // 总条数
       totalNumber: 0,  // 总条数
       totalAmount: 0,
       totalSuccess: 0,
       totalDefeated: 0,
       total3: 0, 
       total4: 0,
       total5: 0,
       total6: 0,
       performNumber: 0,
       performSuccessNumber: 0,
       recordId: "",
       taskRules: "",
       ExecutionId: "",
       dialogTitle: "",
       executionTitle: "",
       totalOprecords: 0,
       adOperationList: [],
       successTableList: [],
       defeatedTableList: [],
       sumNumberTableList: [],
       showVisible: false,
       loading: false,
       adGroupVisible: false,
       advertiseVisible: false,
       keywordVisible: false,
       adCampaignVisible: false,
       priductPositionVisible: false,  // 商品定位
       performObjectVisible: false,    // 选择被执行
       targetVisible: false,   // 自动投放
       keywordExecutionVisible: false,
       advertiseVisibleShow: false,
       timeDivisionBudget2: "",
       labelPosition: "right",
       tableList: [],
       gridData: [],
       adTableList: [],
       numericalAmount: "",  // 数值金额
       addTimeRangeByWeek: [],
       keywordTableList: [],
       keywordFrequency: "",
       adDateTime: "",
       adTimePoint: "",
       keywordDayType: "1",
       pageDateObject: {
         keyword: "",  // 搜索广告
         adCampaign: "",  // 广告活动
         adGroup: "",   // 广告组
         pageSize: 20,
         current: 1
       },
       selectArray: [
         { id: "enabled", name: "启动广告活动" },
         { id: "paused", name: "暂停广告活动" },
         { id: "change", name: "更改预算" },
         { id: "strategy", name: "竞价策略" },
         { id: "scheduled", name: "分时调预算" }
       ],
       operationTypeList: [
         { id: 'fixed' , value: "将预算调至" },
         { id: 'up', value: "提高预算" },
         { id: 'down', value: "降低预算" },
       ],
       pageList: {
         current: 1,
         pageSize: 20,
         adCampaign: ""
       },
       //  商品定位(执行对象)
       pageDateGoods: {
         adCampaign: "",  // 广告活动
         adGroup: "",   // 广告组
         keyword: "",   // 关键词
         pageSize: 20,
         current: 1
       },
       // 自动投放(选择执行对象)
       pageDateTargets: {
        adCampaign: "",  // 广告活动
        adGroup: "",   // 广告组
        pageSize: 20,
        current: 1
       },
       pageInfo: {
         taskStatus: "",   // 任务状态
         objectType: "",   // 任务对象
         keyword: "",   // 定义任务id
         profileId: "",   // 账户id
         current: 1,
         pageSize: 20,
         shopId: "",
         marketplaceId: "",
       },
       form: {     // 广告活动
         taskName: "",      // 任务名称
         activityType: "",  // 活动创建
         adTypes: "sp",     // 广告类型
         applicaRange: "",  // 应用对象范围
       },
       tableDataArray: [],  // 表格数据
       strategyType: "",   // 广告活动操作类型名称
       operationMode: "",
       operationMaxLimit: "",
       operationMinLimit: "",
       dynamicDomain: [],
       adCampaignTimeRangeValue: "",
       campaignFrequency: "1",   // 广告活动执行频率
       chooseDateTime: "",
       chooseTimePoint: "",  // 选择时间点
       adCampaignExecuteDay: "",   //  选择天数
       adGroupExecuteDay: "",
       adExecuteDay: "",
       keywordExecuteDay: "",
       goodExecuteDay: "",
       autoExecuteDay: "",
       formData: {
         taskName: "",    // 任务名称
         adGroupType: "",     // 广告活动创建
         adGroupTypes: "sp",  // 广告类型
         adGroupAppRange: ""
       },
       formInline: {
         taskName: "",   // 任务名称
         createAdver: "",  //  创建广告
         advertisingTypes: "sp",   // 广告(广告类型)
         advertiseApplicaRange: ""
       },
       formLabelAlign: {
         taskName: "",   // 任务名称
         createKeyword: "",    // 创建关键词
         keywordsAdTypes: "sp",  // 广告类型
         keywordApplicaRange: "ids"
       },
       ruleForm: {
         taskName: "",   // 任务名称
         createPosition: "",   // 创建商品定位
         adTypes: "sp"   // 广告类型
       },
       goodsApplicaRange: "ids",   // 商品定位应用范围
       dynamicdateForm: {
         taskName: "",   // 任务名称
         autoTarget: "",   // 创建自动投放
         autoTargetTypes: "sp",  // 广告类型
         autoApplicaRange: "",
       },
       pageInfoOprecords: {
         current: 1,
         pageSize: 20
       },
       operateTypeList: [
         { id: 1, value: "广告活动" },
         { id: 2, value: "广告组" },
         { id: 3, value: "广告位" },
         { id: 4, value: "广告商品" },
         { id: 5, value: "关键词" },
         { id: 6, value: "商品定位" },
         { id: 7, value: "自动投放" }
       ],
       tress: [],
       taskStatusList: [],
       adCampaignList: [],
       adGroupsList: [],
       taskObjectList: [],
       multipleSelection: [],
       tableDataObject: [],
       adGroupTableData: [],
       keywordMinLimit: "",
       keywordMaxLimit: "",
       keywordsOperaType: "",
       multipleTableselected: [],
       performsConditional: [   // 执行条件 
         { id: "impressions", values: "展示量",},
         { id: "clicks", values: "点击量",},
         { id: "cost", values: "花费", },
         { id: "ordered", values: "广告订单量", },
         { id: "sales", values: "销售额", },
         { id: "cvr", values: "CVR", },
         { id: "acos", values: "ACOS",},
         { id: "ctr", values: "点击率",}
       ],
       ExecutionFrequency: [
         { id: "0", value: "一次" },
         { id: "1", value: "每日" },
         { id: "2", value: "每周" },
         { id: "3", value: "每月" }
       ],
       oneWeekList: [
         { id: "0", value: "周日"},
         { id: "1", value: "周一"},
         { id: "2", value: "周二"},
         { id: "3", value: "周三" },
         { id: "4", value: "周四" },
         { id: "5", value: "周五" },
         { id: "6", value: "周六" }
       ],
       appTimeRangeList: [
         {id: 0, name: "当天"},
         {id: 1, name: "昨天"},
         {id: 2, name: "当周"},
         {id: 3, name: "上周"},
         {id: 4, name: "当月"}, 
         {id: 5, name: "上月"}, 
         {id: 6, name: "最近7天"}, 
         {id: 7, name: "最近14天"}, 
         {id: 8, name: "最近30天"}, 
         {id: 9, name: "所有时间"}, 
       ],
       oneMonthDate: [
         { id: "0", value: "1号" },
         { id: "1", value: "2号" },
         { id: "2", value: "3号" },
         { id: "3", value: "4号" },
         { id: "4", value: "5号" },
         { id: "5", value: "6号" },
         { id: "6", value: "7号" },
         { id: "7", value: "8号" },
         { id: "8", value: "9号" },
         { id: "9", value: "10号" },
         { id: "10", value: "11号" },
         { id: "11", value: "12号" },
         { id: "12", value: "13号" },
         { id: "13", value: "14号" },
         { id: "14", value: "15号" },
         { id: "15", value: "16号" },
         { id: "16", value: "17号" },
         { id: "17", value: "18号" },
         { id: "18", value: "19号" },
         { id: "19", value: "20号" },
         { id: "20", value: "21号" },
         { id: "21", value: "22号" },
         { id: "22", value: "23号" },
         { id: "23", value: "24号" },
         { id: "24", value: "25号" },
         { id: "25", value: "26号" },
         { id: "26", value: "27号" },
         { id: "27", value: "28号" },
         { id: "28", value: "29号" },
         { id: "29", value: "30号" },
         { id: "30", value: "31号" },
       ],
       notificationEmail: "",  // 邮件消息
       noticeTime: "",    // 通知时间
       sendResultsList: [
         { id: "0", value: "不通知" },
         { id: "1", value: "每次运行任务时通知" },
         { id: "2", value: "仅在有更改和错误时发送" },
         { id: "3", value: "仅有错误时通知" },
       ],
       pageInfoAdGroup: {
         adGroup: "",   // 广告组(广告活动)
         keyword: "",   // 关键词
         pageSize: 20,  
         current: 1 
       },
       pageDateKeyword: {
         adCampaign: "",  //  广告活动
         adGroup: "",  // 广告组
         keyword: "",  // 关键词
         pageSize: 20,  // 每页显示多少条
         current: 1,   // 当前页
       },
       adTableData: [],
       adCampaignkeyword: [],
       adGroupsKeyword: [],
       totalSelectedList: [],
       dynamicTags: [],
       dynamicTags2: [],
       dynamicTags3: [],
       dynamicTags4: [],
       JudgeConditions: [],
       performFrequency: "",
       goodObjectVisible: false,
       targetsObjectVisible: false,
       keywordMode: "",
       addConditShow: false,
       adGroupNoticeTime: "",
       adGroupOperation: "",
       adGroupObjectVisible: false,  // 广告组执行任务对象
       advertiseExecutiveVisible: false,
       keywordObjectVisible: false,
       adGroupEmail: "",
       adCollection: [],
       keywordNumber: "",
       adGroupSelectData: [],
       adGroupMultipleSele: [],  // 多选事件
       adGroupTotal: 0,  // 总条数
       adCampaignTimeRange: false,
       adGroupFrequency: "0",   // 执行频率
       adGroupTableList: [],
       adGroupDateTime: "",
       adGroupTimePoint: "",   // 执行频率时间
       adEmailAccount: "",
       operationNumber: "",     // 调整金额/比例
       timeDivisionBudget: "",
       dayType: "1",
       adSendResult: "",    // 广告发送结果
       LoopListData: [],
       keywordEmailAccount: "",
       keywordNoticeTime: "",
       goodPositionVisible: false,
       goodPositionList: [],
       goodEmailAccount: "",
       goodNoticeTime: "",
       goodOperaType: "",
       autoTargetVisible: false,
       autoTargetList: [],
       autoTargetFrequency: "",
       autoEmailAccount: "",
       autoTargetType: "",
       permissionSettingTable: [],
       KeywordTableData: [],
       keywordTimePoint1: "",
       adCampaignGoods: [],
       adGroupsGoods: [],
       adCampaignTarget: [],
       adGroupsTarget: [],
       goodsTableList: [],
       goodsMultipleList: [],
       autoTargetTablList: [],
       autoMultipList: [],
       autoTableList: [],
       targetTimePoint1: "",
       autargetTimePoint: "",
       autoNoticeTime: "",
       dynamicTags5: [],
       autoTargeteTimeRange: false,
       autoTargeteValue: "",
       autoTargetAmount: "",
       autoTargetMode: "",
       autoTargetMaxLimit: "",  // 最大值
       autoTargetMinLimit: "",  // 最小值
       timeDivisionTarget: "",
       autoTargetdayType: "1",
       addTimeRangeByWeek7: [],
       frequencyType: "",
       goodsTimePoint1: "",
       goodsPosiTimePoint: "",
       dynamicTags6: [],
       goodsPosiTimeRange: false,
       goodsPositValue: "",
       goodsPositionNumber: "",
       goodsMode: "",
       goodsMinLimit: "",
       goodsMaxLimit: "",
       timeDivisionBudget3: "",
       goodsDayType: "1",
       addTimeRangeByWeek6: [],
       addTimeRangeByKeyword: [],
       chkeywordTimePoint: "",
       KeywordValue: "",
       adGroupMode: "",
       adGroupMaxLimit: "",
       adGroupMinLimit: "",
       adGroupNumber: "",  // 调整金额比例
       adGroupTimeRange: false,
       adGroupValue: "",
       adTimeRangeValue: "",
       batchInfo: {
        type: "",
      },
      idList: [],
      editId: "",
      KeywordTimeRange2: false,
      adCampaignArrList: [],
      adGroupsArrList: [],
      adGroupsGoodsArrList: [],
      adTargetArrList: [],
      GoodPositionTable: [],
      adverTimeRange: false,
      a1: "",
      a2: "",
      a3: "",
      a4: "",
      JudgeConditions11: [
        { id: "2", value: "小于", name: "<" },
        { id: "1", value: "大于", name: ">" }
      ],
      JudgeConditions22: [
        { id: "2", value: "小于", name: "<" },
        { id: "4", value: "小于或等于", name: "≤" },
        { id: "0", value: "等于", name: "=" },
        { id: "1", value: "大于", name: ">" },
        { id: "3", value: "大于或等于", name: "≥" }
      ], 
      totalRecords: 0,
      tableData2: [],
      searchInfo: {
        keyword: "",   // 定时任务名称
        current: 1,
        pageSize: 20,
        startDate: "",
        endDate: "",
      },
      operationVisible: false,
      executionVisible: false,
      pageInfoAmount: {
        current: 1,
        pageSize: 20
      },
      pageInfoSuccess: {
        current: 1,
        pageSize: 20
      },
      pageInfoDefeated: {
        current: 1,
        pageSize: 20
      }
     }
   },
   computed: {
     jj() {
       this.dynamicDomain.forEach(data=> {
         this.performsConditional.forEach((item, index)=> {
           if(data.conditional == item.id) {
             this.performsConditional[index].disabled = true;
           }
         })
       })
       return this.performsConditional
     },
     // isdisabled() {
     //   return (item)=> {
     //     return this.dynamicDomain.find(e=> e.conditional === item.id ? true : false)
     //   }
     // }
   },
   created() {
     this.getPageInit();  // 首页初始化数据
     this.$nextTick(()=> {
       setTimeout(()=> {
         this.asyncSearch();  // 首页查询
       }, 500)
     })
   },
   methods: {
     // 重置
     handleReset() {
       this.searchInfo.keyword = "";
     },
     topTime(val) {
       console.log(val);
       this.searchInfo.startDate = val.value[0];
       this.searchInfo.endDate = val.value[1];
     },
     handleSizeChangerecords(newSize) {
       this.searchInfo.pageSize = newSize;
       this.handle_Search();
     },
     handleCurrentChangerecords(newCrrent) {
       this.searchInfo.current = newCrrent;
       this.handle_Search();
     },
     getID(shopID, marketPlaceId, name, currency, profileId) {
       this.pageInfo.shopId = shopID;
       this.pageInfo.marketplaceId = marketPlaceId;
       this.pageInfo.profileId = profileId;
       this.asyncSearch();
     },
     adCampaignClear() {
       this.pageDateObject.adGroup = "";
       this.adCampaignArrList = this.adGroupsList;
     },
     adGroupsClear() {
       this.pageDateKeyword.adGroup = "";
       this.adGroupsArrList = this.adGroupsKeyword;
     },
     handleSelectkeyword(id) {
       this.adGroupsArrList = this.adGroupsKeyword.filter(item=> {
         return item.campaignId == id;
       })
     },
     adGoodsClear() {
       this.pageDateGoods.adGroup = "";
       this.adGroupsGoodsArrList = this.adGroupsGoods;
     },
     handleSelectGoods(id) {
       this.adGroupsGoodsArrList = this.adGroupsGoods.filter(item=> {
         return item.campaignId == id;
       })
     },
     adTargetClear() {
       this.pageDateTargets.adGroup = "";
       this.adTargetArrList = this.adGroupsTarget;
     },
     handleSelectTarget(id) {
       this.adTargetArrList = this.adGroupsTarget.filter(item=> {
         return item.campaignId == id;
       })
     },
     //状态改变
     changeStatus(id, status) {
      this.loading = true;
      this.ids = [id];
      if (status == "enabled") {
        this.batchInfo.type = "enabled";
      } else if (status == "paused") {
        this.batchInfo.type = "paused";
      }
      let data = {
        ids: this.ids,
        type: this.batchInfo.type,
      };
      updateStatusById(data).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.handleSearch();
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
    },
     openShow(Visible) {
       this.Visible = true;
     },
     // 查询初始化
     getPageInit() {
       pageInit()
        .then(res=> {
          this.taskObjectList = [];
          this.taskStatusList = [];
          if(res.data.code == 200) {
            this.taskObjectList = res.data.data.objectTypeList;
            this.taskStatusList = res.data.data.statusList;
            this.tress = res.data.data.shopList;
            res.data.data.shopList.forEach(item=> {
              item.marketPlaceTree.forEach(data=> {
                if(data.isSelected == 1) {
                  this.pageInfo.profileId = data.profileId;
                  this.pageInfo.shopId = item.id;
                  this.pageInfo.marketplaceId = data.id;
                }
              })
            })
          }else {
            this.$message.error(res.data.message);
          }
        })
     },
     // 首页页面查询  ----------(方法)------------
     asyncSearch() {
       let params = {
         status: this.pageInfo.taskStatus,
         shopId: this.pageInfo.shopId,
         marketplaceId: this.pageInfo.marketplaceId,
         objectType: this.pageInfo.objectType,
         keyword: this.pageInfo.keyword,
         current: this.pageInfo.current,
         pageSize: this.pageInfo.pageSize
       }
       this.loading = true;
       getListPage(params)
        .then(res=> {
          this.loading = false;
          this.tableList = [];
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.tableList = res.data.values;
            this.totalNumber = res.data.pageInfo.total;
          }
        })
     },
     // 启用
     asyncEnable() {
       if (this.multipleSelection.length == 0) {
         this.$message.warning("请先选择要批量操作的内容");
         return false;
       }
       let data = {
         ids: this.idList,
         type: "enabled",
       };
       updateStatusById(data).then((res) => {
         this.loading = false;
         if (res.data.code == 200) {
           this.$message.success(res.data.message);
           this.asyncSearch();
         } else if (res.data.code == 500) {
           this.$message.error(res.data.message);
         }
       });
     },
     // 暂停
     asyncPause() {
       if (this.multipleSelection.length == 0) {
         this.$message.warning("请先选择要批量操作的内容");
         return false;
       }
       let data = {
         ids: this.idList,
         type: "paused",
       };
       updateStatusById(data).then((res) => {
         this.loading = false;
         if (res.data.code == 200) {
           this.$message.success(res.data.message);
           this.asyncSearch();
         } else if (res.data.code == 500) {
           this.$message.error(res.data.message);
         }
       });
     },
     // 删除
     asyncDelete() {
       if (this.multipleSelection.length == 0) {
         this.$message.warning("请先选择要批量操作的内容");
         return false;
       }
       let data = {
         ids: this.idList,
         type: "archived",
       };
       updateStatusById(data).then((res) => {
         this.loading = false;
         if (res.data.code == 200) {
           this.$message.success('删除成功');
           this.asyncSearch();
         } else if (res.data.code == 500) {
           this.$message.error(res.data.message);
         }
       });
     },
     // --------------(首页的分页)---------------
     handleSizeChange1(newSize) {
       this.pageInfo.pageSize = newSize;
       this.asyncSearch();
     },
     handleCurrentChange1(newCurrent) {
       this.pageInfo.current = newCurrent;
       this.asyncSearch();
     },
     // --------------(广告活动执行对象的分页)----------
     handleSizeChange2(newSize) {
       this.pageList.pageSize = newSize;
       this.handleSearch();
     },
     handleCurrentChange2(newCurrent) {
       this.pageList.current = newCurrent;
       this.handleSearch();
     },
     // -------------(广告组执行对象的分页)-------------
     asyncSizeChange(newSize) {
       this.pageInfoAdGroup.pageSize = newSize;
       this.handleadGroupSearch();
     },
     asyncCurrentChange(newCurrent) {
       this.pageInfoAdGroup.current = newCurrent;
       this.handleadGroupSearch();
     },
     //  -------------(广告商品执行对象的分页)-------------
     handleSizeChange3(newSize) {
       this.pageDateObject.pageSize = newSize;
       this.handleAdSearch();
     },
     handleCurrentChange3(newCurrent) {
       this.pageDateObject.current = newCurrent;
       this.handleAdSearch();
     },
     // -------------(关键词执行对象的分页)-------------
     handleSizeChange4(newSize) {
       this.pageDateKeyword.pageSize = newSize;
       this.handleKeywordSearch();
     },
     handleCurrentChange4(newCurrent) {
       this.pageDateKeyword.current = newCurrent;
       this.handleKeywordSearch();
     },
     //  -------------(商品定位执行对象的分页)-------------
     handleSizeChange5(newSize) {
       this.pageDateGoods.pageSize = newSize;
       this.goodPositionSearch();
     },
     handleCurrentChange5(newCurrent) {
       this.pageDateGoods.current = newCurrent;
       this.goodPositionSearch();
     },
     // -------------(自动投放执行对象的分页)-------------
     handleSizeChange6(newSize) {
      this.pageDateTargets.pageSize = newSize;
      this.autoTargetSearch();
     },
     handleCurrentChange6(newCurrent) {
       this.pageDateTargets.current = newCurrent;
       this.autoTargetSearch();
     },
     //  首页table表格多选状态 
     handleSelectionChange(val) {
       this.multipleSelection = val;
       var ids = [];
       this.multipleSelection.forEach((data) => {
        ids.push(data.id);
      });
      this.idList = ids;
     },
     // 广告活动方法  --------------------------------------  
     // 广告活动确定事件
     asyncConfirm() {
       this.tableDataArray = this.multipleTableselected 
       this.performObjectVisible = false;
     },
     // 广告活动新增
     adCampaignConfirm(type1) {
       if(!this.form.taskName) {
        this.$message.error('定时任务名称不能为空');
        return false;
       }
       if(!this.form.activityType) {
         this.$message.error('定时任务类型不能为空');
         return false;
       }
       if(!this.form.applicaRange) {
         this.$message.error('定时应用范围不能为空');
         return false;
       }
       if(this.form.activityType == "change") {
         if(!this.executeType1) {
           this.$message.error('请完善操作类型');
           return false;
         }
       }
       if(!this.dynamicTags) {
         this.$message.error('执行条件不能为空');
         return false;
       }
       if(!this.noticeTime) {
         this.$message.error('请选择通知类型');
         return false;
       }
       // 组装请求参数
       var ids = [];
       this.tableDataArray.forEach(data=> {
         ids.push(data.campaignId)
       })
       var conditionItemList = [];
       this.dynamicDomain.forEach(item=> {
         conditionItemList.push({
           conditional: item.conditional,   // 执行条件
           compare: item.compare,   // 判断大小
           value: item.amount,    // 输入金额
           conditionalRange: this.adCampaignTimeRangeValue,    // 应用时间
         })
       })
       var timeItemList = [];
       this.addTimeRangeByWeek.forEach(data=> {
         let object = {
           changeNumber: data.inputAmount,   // 	数值
           changeType: data.modifyWay,   // 修改方式
           startTime: data.startTime, //  开始时间
           endTime: data.endTime,   // 结束时间
           dayNumber: data.severalWeeks,   //  选择天 周几
         }
         timeItemList.push(object)
       })
       let data= {
         name: this.form.taskName,    // 任务名称
         objectType: "campaigns",   // (定时任务)分类  (广告活动新增)   
         type: this.form.activityType,  // 任务类型
         adType: this.form.adTypes,   // 广告类型sp
         objectRange: this.form.applicaRange == 'ids'?ids.join():this.form.applicaRange,  // 应用对象范围
         conditionItemList: conditionItemList,  // 执行条件   
         executeType: this.campaignFrequency,   // 执行周期类型
         executeDay: this.adCampaignExecuteDay,   // 	执行周期  每周(0-6)  每月(1-31)
         executeDate: this.chooseDateTime.slice(0, 10),
         executeTime: this.campaignFrequency=='0'?this.chooseDateTime.slice(11, 16):this.chooseTimePoint,
         dayType: this.dayType,     //  选择每天/每周
         notificationEmail: this.notificationEmail,  // 通知email
         notificationType:  this.noticeTime,  // 通知类型
         operationType: this.executeType1,    // 操作类型
         operationNumber: parseInt(this.operationNumber),  // 调整金额/比例
         operationMode: this.operationMode,    // 操作方式
         operationMaxLimit: parseInt(this.operationMaxLimit), // 调整最大金额
         operationMinLimit: parseInt(this.operationMinLimit),   // 调整最小金额
         timeDivisionBudget: parseInt(this.timeDivisionBudget),     //  分时竞价的基础预算
         timeItemList: timeItemList,  //  执行时间list
         profileId: this.pageInfo.profileId,
         strategyType: this.strategyType,
         id: this.type1 == 2?this.editId: "",  
       }
       if(type1 == 1) {
         addScheduled(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);   // 提示
             this.adCampaignVisible = false;  
             this.asyncSearch();  // 刷新页面
             this.$nextTick(()=> {
               this.$refs.multipleTable.clearSelection()
             })
           }
         })
       }else if(type1 == 2) {
         editScheduled(data)
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);   // 提示
              this.adCampaignVisible = false;  
              this.asyncSearch();  // 刷新页面
              this.$nextTick(()=> {
                this.$refs.multipleTable.clearSelection()
              })
            }
          })
       }
     },
     // 执行对象多选数据
     handleSelectionChange2(val) {
       this.multipleTableselected = val;
     },
     // 广告组方法  
     handleSelectionChange5(val) {
       this.permissionSettingTable = val;
     },
     //  广告组新增
     adGroupConfirm(type2) {
       if(!this.formData.taskName) {
         this.$message.error('定时任务名称不能为空');
         return false;
       }
       if(!this.formData.adGroupType) {
         this.$message.error('定时任务类型不能为空');
         return false;
       }
       if(this.formData.adGroupType == "change") {
         if(!this.adGroupOperation) {
           this.$message.error('请完善操作类型');
           return false;
         }
       }
       if(!this.adGroupFrequency) {
         this.$message.error('请完善执行频率');
         return false;
       }
       if(!this.adGroupNoticeTime) {
         this.$message.error('请选择通知类型');
         return false;   
       }
       var ids = [];
       this.adGroupTableList.forEach(item=> {
         ids.push(item.adGroupId)
       })
       var conditionItemList2 = [];
       this.gridData.forEach(item=> {
          let object = {
            conditional: item.conditional,  // 执行条件
            compare: item.compare,   // 判断大小
            value: item.amount,    // 输入金额
            conditionalRange: this.adGroupValue,  
          }
          conditionItemList2.push(object);
       })
       let data = {
         name: this.formData.taskName,   //  任务名称
         objectType: "groups",   // (定时任务)分类  (广告活动新增)
         type: this.formData.adGroupType,  // 任务类型
         adType: this.formData.adGroupTypes,   // 广告类型sp
         objectRange: this.formData.adGroupAppRange == 'ids'?ids.join():this.formData.adGroupAppRange,  // 应用对象范围
         conditionItemList: conditionItemList2,  // 执行条件
         executeType: this.adGroupFrequency,    // 执行周期类型
         executeDay: this.adGroupExecuteDay,    // 执行周期 每周(0-6)
         executeDate: this.adGroupDateTime.slice(0, 10),
         executeTime: this.adGroupFrequency=='0'?this.adGroupDateTime.slice(11, 16):this.adGroupTimePoint,
         operationType: this.adGroupOperation,   // 操作类型名称
         operationMode: this.adGroupMode,   // 操作方式
         operationMaxLimit: parseInt(this.adGroupMaxLimit),  // 调整最大金额 
         operationMinLimit: parseInt(this.adGroupMinLimit),  // 调整最小金额
         operationNumber:  parseInt(this.adGroupNumber),   // 调整金额比例
         notificationEmail: this.adGroupEmail,   // 通知email
         notificationType: this.adGroupNoticeTime,  // 通知类型
         profileId: this.pageInfo.profileId,
         id: this.type2 == 2?this.editId: "",
       }
       if(type2 == 1) {
         addScheduled(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);   // 提示
             this.adGroupVisible = false;
             this.asyncSearch();  // 刷新页面
             this.$nextTick(()=> {
               this.$refs.multipleTable2.clearSelection();
             })
           }
         })
       }else if(type2 == 2) {
         editScheduled(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);   // 提示
             this.adGroupVisible = false;
             this.asyncSearch();  // 刷新页面
             this.$nextTick(()=> {
               this.$refs.multipleTable2.clearSelection();
             })
           }
         })
       }
     },
     // 广告组列表多选项
     handleSelectionChange3(val) {
       this.adGroupMultipleSele = val;
     }, 
     handleKeywordConfirm() {
       for (let g=0;  g<this.LoopListData.length; g++) {
         if(!this.LoopListData[g].amount) {
           this.$message.error("执行条件数值不可为空");
           return false;
         }
       }
       this.dynamicTags4 = this.LoopListData.map(data=> {
         return data.conditionsvalues + data.judgename + data.amount;
       })
       this.keywordExecutionVisible = false;
       this.KeywordTimeRange2 = true;
     },
     handleAddition() {
       this.LoopListData.push({
         percentShow: false,
         JPYShow: false,
         conditional: "",    // 执行条件
         conditionsvalues: "",
         compare: "",   // 判断大小
         judgevalues: "",
         judgename: "",
         amount: ""    // 数值金额
       })
     },
     keywordToggleConditions(item, index5) {
       this.LoopListData[index5].conditional = item.id;
       this.LoopListData[index5].conditionsvalues = item.values;
     },
     keywordChangeCompare(item, index5) {
       this.LoopListData[index5].compare = item.id;
       this.LoopListData[index5].judgename = item.name;
       this.LoopListData[index5].judgevalues = item.value;
     },
     // 重置事件 
     reset() {
       this.pageInfo.taskStatus = "",
       this.pageInfo.objectType = "",
       this.pageInfo.keyword = ""
     },
     handleCommand(command) {
       if(command == 1) {    // 广告活动
         this.type1 = 1;
         this.adCampaignInit();
         this.adCampaignVisible = true;
       }else if(command == 2) {   // 广告组
         this.type2 = 1;
         this.adGroupInit();
         this.adGroupVisible = true;
       }else if(command == 3){    // 广告位
         this.$refs.subComponents.type3 = 1;
         this.adverInit();
         this.$refs.subComponents.advertisingVisible = true;
       }else if(command == 4) {   // 广告
        this.type4 = 1;
        this.advertiseInit();
        this.advertiseVisible = true;
       }else if(command == 5) {   // 关键词
         this.type5 = 1;
         this.keywordInit();
         this.keywordVisible = true;
       }else if(command == 6) {   // 商品定位
         this.type6 = 1;
         this.goodsInit();
         this.priductPositionVisible = true;
       }else if(command == 7) {   // 自动投放
         this.type7 = 1;
         this.targetInit();
         this.targetVisible = true;
       }  
     },
     // 广告活动清空数据 
     adCampaignInit() {
       this.form.taskName = "";
       this.form.activityType = "";
       this.form.applicaRange = "";
       this.tableDataArray = [];
       this.strategyType = "";
       this.executeType1 = "";
       this.operationNumber = "";
       this.operationMode = "";
       this.operationMaxLimit = "";
       this.operationMinLimit = "";
       this.dynamicTags = [];
       this.dynamicDomain = [];
       this.adCampaignTimeRangeValue = "";
       this.adCampaignTimeRange = false;
       this.campaignFrequency = "";
       this.chooseDateTime = "";
       this.chooseTimePoint = "";
       this.adCampaignExecuteDay = "";
       this.notificationEmail = "";
       this.noticeTime = "";
       if(this.multipleTableselected.length) {
         this.$nextTick(()=> {
           this.$refs.multipleTable.clearSelection();   // 表格复选框清空
         })
       }
     },
     // 广告组清空数据
     adGroupInit() {
       this.formData.taskName = "";
       this.formData.adGroupType = "";
       this.formData.adGroupAppRange = "";
       this.adGroupTableList = [];
       this.adGroupOperation = "";
       this.adGroupNumber = "";
       this.adGroupMode = "";
       this.adGroupMaxLimit = "";
       this.adGroupMinLimit = "";
       this.gridData = [];
       this.adGroupTimeRange = false;
       this.dynamicTags2 = [];
       this.adGroupValue = "";
       this.adGroupDateTime = "";
       this.adGroupTimePoint = "";
       this.adGroupExecuteDay = "";
       this.adGroupEmail = "";
       this.adGroupNoticeTime = "";  
       if(this.adGroupMultipleSele.length) {
         this.$nextTick(()=> {
           this.$refs.multipleTable2.clearSelection();  // 表格复选框清空
         })
       }
     },
     // 广告位清空数据
     adverInit() {
       this.$refs.subComponents.formModel.taskName = "";
       this.$refs.subComponents.formModel.createAdverte = "";
       this.$refs.subComponents.formModel.adverApplicaRange = "";
       this.$refs.subComponents.advertiseTableData = [];
       this.$refs.subComponents.operationTop = "";
       this.$refs.subComponents.operationProduct = "";
       this.$refs.subComponents.dynamicTags3 = [];
       this.$refs.subComponents.collectionList = [];
       this.$refs.subComponents.adTimeRangeShow = false;
       this.$refs.subComponents.conditionalTimeRangeValue = "";
       this.$refs.subComponents.frequencyType = "";
       this.$refs.subComponents.adverTimePoint = "";
       this.$refs.subComponents.executeDay = "";
       this.$refs.subComponents.adverTimePoint = "";
       this.$refs.subComponents.adverEmail  = "";
       this.$refs.subComponents.adverNoticeTime = "";
       if(this.$refs.subComponents.multipleSelection.lenght) {
         this.$nextTick(()=> {
           this.$refs.subComponents.$refs.multipleTable3.clearSelection();
         })
       }
     },  
     // 广告清空数据
     advertiseInit() {
       this.formInline.taskName = "";
       this.formInline.createAdver = "";
       this.formInline.advertiseApplicaRange = "";
       this.adCollection = [];
       this.dynamicTags3 = [];
       this.adverTimeRange = false;
       this.adTableList = [];
       this.adTimeRangeValue = "";
       this.performFrequency = "";
       this.adDateTime = "";
       this.adTimePoint = "";
       this.adExecuteDay = "";
       this.adEmailAccount = "";
       this.adSendResult = "";
       if(this.totalSelectedList.length) {
         this.$nextTick(()=> {
           this.$refs.multipleTable4.clearSelection();
         })
       }
     },
     // 关键词清空数据
     keywordInit() {
       this.formLabelAlign.taskName = "";
       this.formLabelAlign.createKeyword = "";
       this.formLabelAlign.keywordApplicaRange = "";
       this.keywordTableList = [];
       this.keywordsOperaType = "";
       this.keywordNumber = "";
       this.keywordMode ="";
       this.keywordMaxLimit = "";
       this.keywordMinLimit = "";
       this.keywordDayType = "";
       this.addTimeRangeByKeyword = [];
       this.LoopListData = [];
       this.dynamicTags4 = [];
       this.KeywordTimeRange2 = false;
       this.KeywordValue = "";
       this.keywordFrequency = "";
       this.keywordTimePoint1 = "";
       this.chkeywordTimePoint = "";
       this.keywordExecuteDay = "";
       this.keywordEmailAccount = "";
       this.keywordNoticeTime= "";
       if(this.permissionSettingTable.length) {
         this.$nextTick(()=> {
           this.$refs.multipleTable5.clearSelection();
         })
       }
     },
     // 商品定位清空数据
     goodsInit() {
       this.ruleForm.taskName = "";
       this.ruleForm.createPosition = "";
       this.GoodPositionTable = [];
       this.goodsApplicaRange = "";
       this.timeDivisionBudget3 = "";
       this.goodsDayType = "";
       this.addTimeRangeByWeek6 = [];
       this.goodOperaType = "";
       this.goodsPositionNumber = "";
       this.goodsMode = "";
       this.goodsMaxLimit = "";
       this.goodsMinLimit = "";
       this.goodPositionList = [];
       this.goodsPosiTimeRange = false;
       this.dynamicTags6 = [];
       this.goodsPositValue = "";
       this.frequencyType = "";
       this.goodsTimePoint1 = "";
       this.goodsPosiTimePoint = "";
       this.goodExecuteDay = "";
       this.goodEmailAccount = "";
       this.goodNoticeTime = "";
       if(this.goodsMultipleList.length) {
         this.$nextTick(()=> {
           this.$refs.multipleTable6.clearSelection();
         })
       }
     },
     // 自动投放数据清空 
     targetInit() {
       this.dynamicdateForm.taskName = "";
       this.dynamicdateForm.autoTarget = "";
       this.autoTableList = [];
       this.dynamicdateForm.autoApplicaRange = ""; 
       this.timeDivisionTarget = "";
       this.autoTargetdayType = "";
       this.addTimeRangeByWeek7 = [];
       this.autoTargetType = "";
       this.autoTargetAmount = "";
       this.autoTargetMode = "";
       this.autoTargetMaxLimit = "";
       this.autoTargetMinLimit = "";
       this.autoTargetList = [];
       this.autoTargeteTimeRange = false;
       this.dynamicTags5 = [];
       this.autoTableList = [];
       this.autoTargeteValue = "";
       this.autoTargetFrequency = "";
       this.targetTimePoint1 = "";
       this.autargetTimePoint = "";
       this.autoExecuteDay = "";
       this.autoEmailAccount = "";
       this.autoNoticeTime = "";
       if(this.autoMultipList.length) {
         this.$nextTick(()=> {
           this.$refs.multipleTable7.clearSelection();
         })
       }
     },
     tableSearch() {
       this.pageList.current = 1;
       this.pageList.pageSize = 20;
       this.handleSearch(); 
     },    
     // 选择广告活动
     onClickAdCampaign() {
       this.handleSearch();
       this.performObjectVisible = true;
     },
     // (广告活动)查询应用对象 
     handleSearch() {
       let params = {
         objectType: "campaigns",   // 广告活动
         profileId: this.pageInfo.profileId,
         content: this.pageList.adCampaign,
         pageSize: this.pageList.pageSize,
         current: this.pageList.current,
       }
       listObjectByType(params)
        .then(res=> {
          this.tableDataObject = [];
          if(res.status == 200) {
            var ids = [];
            this.tableDataArray.forEach(data=> {
              ids.push(data.campaignId)
            })
            this.tableDataObject = res.data.values;
            this.total = res.data.pageInfo.total;
            this.$nextTick(()=> {
              this.tableDataObject.forEach((row, index)=> {
                if(ids.indexOf(row.campaignId)>=0) {
                  this.$refs.multipleTable.toggleRowSelection(this.tableDataObject[index], true);
                }
              })  
            })
          }
       })
     },
     // (广告组) 查询应用对象
     handleadGroupSearch() {
       let params = {
         objectType: "adGroups",
         content: this.pageInfoAdGroup.keyword,
         campaignId: this.pageInfoAdGroup.adGroup,   // 广告组id
         pageSize: this.pageInfoAdGroup.pageSize,
         current: this.pageInfoAdGroup.current,
         profileId: this.pageInfo.profileId
       }
       listObjectByType(params)
       .then(res=> {
         this.adGroupTableData = [];
         if(res.status == 200) {
           var ids = [];
           this.adGroupTableData.forEach(item=> {
             ids.push(item.adGroupId)
           })
           this.adGroupTableData = res.data.values;
           this.adGroupTotal = res.data.pageInfo.total;
           this.$nextTick(()=> {
             this.adGroupTableData.forEach(row=> {
               if(ids.indexOf(row.adGroupId)>=0) {
                 this.$refs.multipleTable2.toggleRowSelection(row, true);
               }
             })
           })
         }
       })
     },
     // 选择广告组
     onClickAdGroup() {
       this.getAppObjectInit();
       this.handleadGroupSearch();
       this.adGroupObjectVisible = true;
     },
     // 查询应用对象初始化
     getAppObjectInit() {
       let params = {
         objectType: "groups",
         profileId: this.pageInfo.profileId
       }
       listObjectInit(params)
        .then(res=> {
          this.adGroupSelectData = [];
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.adGroupSelectData = res.data.data.campaignList;
          }
        })
     },
     // 广告组弹框确认
     addAdGroupConfirm() {
      this.adGroupTableList = this.adGroupMultipleSele;
       this.adGroupObjectVisible = false;
     },
     // 移除按钮
     adGroupRemove(index) {
       this.adGroupTableList.splice(index);
     },
     goodPositionRemove(index) {
       this.GoodPositionTable.splice(index, 1);
     },
     // 广告活动执行条件添加  
     handlePlus() {
       this.dynamicDomain.push({
         percentShow: false,
         JPYShow: false,
         conditional: "",    // 执行条件
         conditionsvalues: "",
         compare: "",   // 判断大小
         judgevalues: "",
         judgename: "",
         amount: "",    // 数值金额
       })
     },
     toggleSwitch(item, _index) {    //  这个index  外层的index
       this.dynamicDomain[_index].conditional = item.id;
       this.dynamicDomain[_index].conditionsvalues = item.values;
     },
     toggleChange(item2, _index) {
       this.dynamicDomain[_index].compare = item2.id;
       this.dynamicDomain[_index].judgename = item2.name;
       this.dynamicDomain[_index].judgevalues = item2.value;
     },
     // 确认按钮 
     handleConfirm() {
       for (let i = 0; i<this.dynamicDomain.length; i++) {
         if(!this.dynamicDomain[i].amount) {
           this.$message.error("执行条件数值不可为空");
           return false;
         }
       }
       this.dynamicTags = this.dynamicDomain.map(data=> {
         return data.conditionsvalues + data.judgename + data.amount;
       })
       this.showVisible = false;
       this.adCampaignTimeRange = true;
     },
     // 广告确认
     adverConfirm(type4) {
       if(!this.formInline.taskName) {
         this.$message.error('定时任务名称不能为空');
         return false;
       }
       if(!this.formInline.createAdver) {
         this.$message.error('定时任务类型不能为空');
         return false;
       }
       if(!this.formInline.advertiseApplicaRange) {
         this.$message.error('应用范围不能为空');
         return false;
       }
       if(!this.adSendResult) {
         this.$message.error('请选择通知类型');
         return false;
       }
       // 组装请求参数
       var ids = [];
       this.adTableList.forEach(item=> {
         ids.push(item.adId)
       })
       var conditionItemList3 = [];
       this.adCollection.forEach(data=> {
         let object = {
           conditional: data.conditional,  // 执行条件
           compare: data.compare,   // 判断大小
           value: data.amount,    // 输入金额
           conditionalRange: this.adTimeRangeValue
         }
         conditionItemList3.push(object);
       })
       let data = {
         name: this.formInline.taskName,  // 任务名称
         objectType: "product",
         type: this.formInline.createAdver,  // 任务类型
         adType: this.formInline.advertisingTypes,  // 广告类型
         objectRange: this.formInline.advertiseApplicaRange == 'ids'?ids.join():this.formInline.advertiseApplicaRange,  // 应用对象范围
         conditionItemList: conditionItemList3,  // 执行条件
         executeType: this.performFrequency,    // 执行周期类型
         executeDay: this.adExecuteDay,    // 执行周期 每周(0-6)
         executeDate: this.adDateTime.slice(0, 10),
         executeTime: this.performFrequency=='0'?this.adDateTime.slice(11, 16):this.adTimePoint,
         notificationEmail: this.adEmailAccount,
         notificationType: this.adSendResult,
         profileId: this.pageInfo.profileId,
         id: this.type4 == 2?this.editId: "",
       } 
       if(type4 == 1) {
         addScheduled(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.advertiseVisible = false;
             this.asyncSearch();   // 刷新列表
             this.$nextTick(()=> {
               this.$refs.multipleTable4.clearSelection();
             })
           }
         })
       }else if(type4 == 2) {
         editScheduled(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.advertiseVisible = false;
             this.asyncSearch();   // 刷新列表
             this.$nextTick(()=> {
               this.$refs.multipleTable4.clearSelection();
             })
           }
         })
       }

     },
     // 关键词确认
     keywordConfirm(type5) {
       if(!this.formLabelAlign.taskName) {
         this.$message.error('定时任务名称不能为空');
         return false;
       }
       if(!this.formLabelAlign.createKeyword) {
         this.$message.error('定时任务类型不能为空');
         return false; 
       }
       if(!this.formLabelAlign.keywordApplicaRange) {
         this.$message.error('应用范围不能为空');
         return false; 
       }
       if(!this.keywordNoticeTime) {
         this.$message.error('请选择通知类型');
         return false;
       }
       var ids = [];
       this.keywordTableList.forEach(data=> {
         ids.push(data.keywordId)
       })
       var conditionItemList4 = [];
       this.LoopListData.forEach(item=> {
         conditionItemList4.push({
           conditional: item.conditional, // 执行条件
           compare: item.compare,   // 判断大小
           value: item.amount,    // 输入金额
           conditionalRange: this.KeywordValue,   // 应用时间
         })
       })
       var timeItemList2 = [];
       this.addTimeRangeByKeyword.forEach(data=> {
         timeItemList2.push({
           changeNumber: data.inputAmount,   // 	数值
           changeType: data.modifyWay,   // 修改方式
           startTime: data.startTime, //  开始时间
           endTime: data.endTime,   // 结束时间
           dayNumber: data.severalWeeks,   //  选择天 周几
         })
       })
       let data = {
         name: this.formLabelAlign.taskName,
         objectType: "keywords",
         adType: this.formLabelAlign.keywordsAdTypes,
         type: this.formLabelAlign.createKeyword,
         objectRange: ids.join(),   // 应用对象范围 
         conditionItemList: conditionItemList4,
         executeType: this.keywordFrequency,
         executeDay: this.keywordExecuteDay,   // 	执行周期  每周(0-6)  每月(1-31)
         executeDate: this.keywordTimePoint1.slice(0, 10),
         executeTime: this.keywordFrequency=='0'?this.keywordTimePoint1.slice(11, 16):this.chkeywordTimePoint,
         dayType: this.keywordDayType,  
         notificationEmail: this.keywordEmailAccount,
         notificationType: this.keywordNoticeTime,
         operationType: this.keywordsOperaType,
         operationNumber: parseInt(this.keywordNumber),
         operationMode: this.keywordMode,
         operationMaxLimit: parseInt(this.keywordMaxLimit),
         operationMinLimit: parseInt(this.keywordMinLimit),
         timeDivisionBudget: this.timeDivisionBudget2,   // 关键词
         timeItemList: timeItemList2,  //  执行时间list
         profileId: this.pageInfo.profileId,
         id: this.type5 == 2?this.editId: "",
       }
       if(type5 == 1) {
         addScheduled(data) 
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.keywordVisible = false;
             this.asyncSearch();  // 刷新页面
             this.$nextTick(()=> {
               this.$refs.multipleTable5.clearSelection();
             })
           }
         })
       }else if(type5 == 2) {
         editScheduled(data) 
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.keywordVisible = false;
              this.asyncSearch();  // 刷新页面
              this.$nextTick(()=> {
                this.$refs.multipleTable5.clearSelection();
              })
            }
          })
       }
     },
     addKeywordTimeRange() {
       if(this.keywordDayType == "1") {
         this.addTimeRangeByKeyword.push({
          startTime: "",  
          endTime: "",
          modifyWay: "",     //  修改方式
          inputAmount: "",   // 输入金额
        })
       }else if(this.keywordDayType == "2") {
         this.addTimeRangeByKeyword.push({
           severalWeeks: "",
           startTime: "",
           endTime: "",
           modifyWay: "",    // 修改方式
           inputAmount: ""   // 输入金额
         })
       }  
     },
     handleByKeyword(val) {
       this.addTimeRangeByKeyword = [];
       if(val == 1) {
         this.addTimeRangeByKeyword.push({
           startTime: "", 
           endTime: "",
           modifyWay: "",     //  修改方式
           inputAmount: "",   // 输入金额
         })
       }else if(val == 2) {
         this.addTimeRangeByKeyword.push({
           severalWeeks: "",
           startTime: "",
           endTime: "",
           modifyWay: "",    // 修改方式
           inputAmount: ""   // 输入金额
         })
       }
     },
     // 关闭按钮(广告活动)
     handleClose1(tag, index) {
       this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
       this.dynamicDomain.forEach((item, _self)=> {
         if(index == _self) {
           this.dynamicDomain.splice(_self, 1);
         }
       })
     },
     // 关闭按钮(广告组)
     handleClose2(tag, index) {
       this.dynamicTags2.splice(this.dynamicTags2.indexOf(tag), 1);
       this.gridData.forEach((item, _self2)=> {
         if(index == _self2) {
            this.gridData.splice(_self2, 1);
         }
       })
     },
     handleDelete(_index) {
       this.dynamicDomain.splice(_index, 1);
     },
     handle_remove(index) {
       this.tableDataArray.splice(index, 1)
     },
     // 添加时间段
     addTimeRange() {
       if(this.dayType == "1") {
         this.addTimeRangeByWeek.push({
           startTime : "",  
           endTime: "",
           modifyWay: "",     //  修改方式
           inputAmount: "",   // 输入金额
         })
       }else if(this.dayType == "2") {
         this.addTimeRangeByWeek.push({
           severalWeeks: "",
           startTime: "", 
           endTime: "",
           modifyWay: "",    // 修改方式
           inputAmount: ""   // 输入金额
         })
       }
     },
     handleRemoveByCampaign(index) {
       this.addTimeRangeByWeek.splice(index, 1);
     },
     handleRemoveBykey(index) {
       this.addTimeRangeByKeyword.splice(index, 1);
     },
     handlePresetIn(val) {
       this.addTimeRangeByWeek = [];
       if(val == 1) {
         this.addTimeRangeByWeek.push({
           startTime: "",  
           endTime: "",
           modifyWay: "",    //  修改方式
           inputAmount: "",  // 输入金额
         })
       }else if(val == 2) {
         this.addTimeRangeByWeek.push({
           severalWeeks: "",
           startTime: "",
           endTime: "",
           modifyWay: "",    // 修改方式
           inputAmount: ""   // 输入金额
         })
       }
     },
     // 广告组执行条件添加
     handleAdd() {
       this.gridData.push({
         percentShow: false,
         JPYShow: false,
         conditional: "",
         compare: "",
         amount: "",
         conditionsvalues: "",   // 执行条件数据
         judgevalues: "",
         judgename: "",
       })
     },
     // 广告组执行条件确认
     onClickConfirm() {
       for (let t = 0; t<this.gridData.length; t++) {
         if(!this.gridData[t].amount) {
           this.$message.error("执行条件数值不可为空");
           return false;
         }
       }
       this.dynamicTags2 = this.gridData.map(data=> {
         return data.conditionsvalues + data.judgename + data.amount;
       }) 
       this.addConditShow = false;
       this.adGroupTimeRange = true;
     },
     adGroupToggleConditions(item, index2) {
       this.gridData[index2].conditionsvalues = item.values;
       this.gridData[index2].conditional = item.id;
     },
     adGroupChangeCompare(item, index2) {
       this.gridData[index2].compare = item.id;
       this.gridData[index2].judgevalues = item.value;
       this.gridData[index2].judgename = item.name;
     },
     //  广告执行条件
     handleAppend() {
       this.adCollection.push({
         percentShow: false,
         JPYShow: false,
         conditional: "",
         compare: "",
         amount: "",
         conditionsvalues: "",   // 执行条件数据
         judgevalues: "",
         judgename: "",
       })
     },
     adToggleConditions(item, index4) {
       this.adCollection[index4].conditional = item.id;
       this.adCollection[index4].conditionsvalues = item.values;
     },
     adChangeCompare(item ,index4) {
       this.adCollection[index4].compare = item.id;
       this.adCollection[index4].judgename = item.name;
       this.adCollection[index4].judgevalues = item.value;
     },
     handleClose4(tag, index4) {
       this.dynamicTags3.splice(this.dynamicTags3.indexOf(tag), 1);
       this.adCollection.forEach((item, _self3)=> {
         if(index4 == _self3) {
            this.adCollection.splice(_self3, 1);
         }
       })
     },
     // 关闭按钮(关键词) 
     handleClose5(tag, index5) {
       this.dynamicTags4.splice(this.dynamicTags4.indexOf(tag), 1);
       this.LoopListData.forEach((item, _self4)=> {
         if(index5 == _self4) {
            this.LoopListData.splice(_self4, 1);
         }
       })
     },
     // 广告组执行条件删除
     deleteEvent(index) {
       this.gridData.splice(index, 1);
     },
     // 广告弹框选择广告  
     onClickAdver() {
       this.handleAdSearch();
       this.getApplicationObject();
       this.advertiseVisibleShow = true;
     },
     // 查询应用对象
     handleAdSearch() {
       let params = {
         objectType: "product",
         content: this.pageDateObject.keyword,
         campaignId: this.pageDateObject.adCampaign,
         groupId: this.pageDateObject.adGroup,
         profileId: this.pageInfo.profileId,
         pageSize: this.pageDateObject.pageSize,
         current: this.pageDateObject.current
       }
       listObjectByType(params)
       .then(res=> {
         this.adTableData = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           var ids = [];
           this.adTableData.forEach(item=> {
             ids.push(item.adId)
           })
           this.adTableData = res.data.values;
           this.total3 = res.data.pageInfo.total;
           this.$nextTick(()=> {
             this.adTableData.forEach(row=> {
               if(ids.indexOf(row.adId)>=0) {
                 this.$refs.multipleTable4.toggleRowSelection(row, true);
               }
             })
           })
         }
       })
     },
     //  广告弹框选择广告初始化数据
     getApplicationObject() {
       let params = {
         objectType: "product",
         profileId: this.pageInfo.profileId
       }
       listObjectInit(params)
       .then(res=> {
          this.adCampaignList = [];
          this.adGroupsList = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.adCampaignList = res.data.data.campaignList;
           this.adGroupsList = res.data.data.groupsList;
         }
       })
     },
     handleSelectionChange4(val) {
       this.totalSelectedList = val;
     },
     // 新增广告执对象
     addAdConfirm() {
       this.adTableList = this.totalSelectedList;
       this.advertiseVisibleShow = false;
     },
     deleteTab(index) {
       this.adTableList.splice(index, 1);
     },
     asyncClickConfirm() {
       for (let m=0; m<this.adCollection.length; m++) {
         if(!this.adCollection[m].amount) {
           this.$message.error("执行条件数值不可为空");
           return false;
         }
       }
       this.dynamicTags3 = this.adCollection.map(data=> {
         return data.conditionsvalues + data.judgename + data.amount;
       }) 
       this.advertiseExecutiveVisible = false;
       this.adverTimeRange = true;
     },
     handle_select(id) {
       this.adCampaignArrList = this.adGroupsList.filter(item=> {
         return item.campaignId == id;
       })
     },
     //  关键词选择关键词
     onClickKeyword() {
       this.getAdBouncedKeyword();
       this.handleKeywordSearch();
       this.keywordObjectVisible = true;
     },
     getAdBouncedKeyword() {
       let params = {
         objectType: "keywords",
         profileId: this.pageInfo.profileId
       }
       listObjectInit(params)
       .then(res=> {
         this.adGroupsKeyword = [];
         this.adCampaignkeyword = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.adCampaignkeyword = res.data.data.campaignList;
           this.adGroupsKeyword = res.data.data.groupsList;
         }
       })
     },
     // 查询应用对象
     handleKeywordSearch() {
       let params = {
         objectType: "keywords",
         content: this.pageDateKeyword.keyword,
         campaignId: this.pageDateKeyword.adCampaign,
         groupId: this.pageDateKeyword.adGroup,
         profileId: this.pageInfo.profileId,
         pageSize: this.pageDateKeyword.pageSize,
         current: this.pageDateKeyword.current
       }
       listObjectByType(params)
       .then(res=> {
         this.KeywordTableData = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           var ids = [];
           this.keywordTableList.forEach(data=> {
             ids.push(data.keywordId)
           })
           this.KeywordTableData = res.data.values;
           this.total4 = res.data.pageInfo.total;
           this.$nextTick(()=> {
             this.KeywordTableData.forEach((row, index)=> {
               if(ids.indexOf(row.keywordId)>=0) {
                 this.$refs.multipleTable5.toggleRowSelection(this.KeywordTableData[index], true);
               }
             })
           })
         }
       })
     },
     //  关键词新增确认
     addKeywordConfirm() {
       this.keywordTableList = this.permissionSettingTable;
       this.keywordObjectVisible = false; 
     },
     handleRemoveKeyword(index) {
       this.keywordTableList.splice(index, 1);
     },
     onClickGoodPosition() {
       this.goodPositionSearch();  // 查询应用对象
       this.getObjectInitByGoods();
       this.goodObjectVisible = true;
     },
     //  查询应用对象初始化(商品定位)
     getObjectInitByGoods() {
       let params = {
         objectType: "targets",   // 商品定位
         profileId: this.pageInfo.profileId,
       }
       listObjectInit(params)
       .then(res=> {
         this.adCampaignGoods = [];
         this.adGroupsGoods = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.adCampaignGoods = res.data.data.campaignList;
           this.adGroupsGoods = res.data.data.groupsList;
         }
       })
     },
     //  查询应用对象(商品定位)
     goodPositionSearch() {
       let params = {
         objectType: "targets",
         content: this.pageDateGoods.keyword,
         campaignId: this.pageDateGoods.adCampaign,
         groupId: this.pageDateGoods.adGroup,
         profileId: this.pageInfo.profileId,
         pageSize: this.pageDateGoods.pageSize,
         current: this.pageDateGoods.current
       }
       listObjectByType(params)
       .then(res=> {
         var ids = [];
         this.GoodPositionTable.forEach(item=> {
           ids.push(item.targetId)
         })
         this.goodsTableList = res.data.values;
         this.total5 = res.data.pageInfo.total;
         this.$nextTick(()=> {
           this.goodsTableList.forEach(row=> {
            if(ids.indexOf(row.targetId)>0) {
              this.$refs.multipleTable6.toggleRowSelection(row, true);
            }
           })
         })
       })
     },
     // 自动投放选择自动投放
     onClickAutoTarget() {
       this.getObjectInitByTarget();
       this.autoTargetSearch();
       this.targetsObjectVisible = true;
     },
     // 应用对象初始化(自动投放)
     getObjectInitByTarget() {
       let params = {
         objectType: "auto",   // 商品定位
         profileId: this.pageInfo.profileId,
       }
       listObjectInit(params)
       .then(res=> {
         this.adCampaignTarget = [];
         this.adGroupsTarget = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message)
         }else {
           this.adCampaignTarget = res.data.data.campaignList;
           this.adGroupsTarget = res.data.data.groupsList;
         }
       })
     },
     // 查询应用对象(自动投放)
     autoTargetSearch() {
       let params = {
         objectType: "auto",
         campaignId: this.pageDateTargets.adCampaign,
         groupId: this.pageDateTargets.adGroup,
         profileId: this.pageInfo.profileId,
         pageSize: this.pageDateTargets.pageSize,
         current: this.pageDateTargets.current
       }
       listObjectByType(params)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           var ids = [];
           this.autoTableList.forEach(data=> {
             ids.push(data.targetId)
           })
           this.autoTargetTablList = res.data.values;
           this.total6 = res.data.pageInfo.total;
           this.$nextTick(()=> {
             this.autoTargetTablList.forEach(row=>{
               if(ids.indexOf(row.targetId)>=0) {
                 this.$refs.multipleTable7.toggleRowSelection(row, true);
               }
             })
           })
         }
       })
     },
     handleSelectionChange6(val) {
       this.goodsMultipleList = val;
     },
     // 添加商品定位 
     addGoodsConfirm() {
       this.GoodPositionTable = this.goodsMultipleList;
       this.goodObjectVisible = false;
     },
     handleSelectionChange7(val) {
       this.autoMultipList = val;
     },
     addTargetConfirm() {
       this.autoTableList = this.autoMultipList;
       this.targetsObjectVisible = false;
     },
     autoTargetRemove(index) {
       this.autoTableList.splice(index, 1);
     },
     // 商品定位确认  
     goodPositionConfirm(type6){
       if(!this.ruleForm.taskName) {
         this.$message.error('定时任务名称不能为空');
         return false;
       }
       if(!this.ruleForm.createPosition) {
         this.$message.error('定时任务类型不能为空');
         return false; 
       }
       if(!this.goodsApplicaRange) {
         this.$message.error('应用范围不能为空');
         return false; 
       }  
       if(!this.goodNoticeTime) {
         this.$message.error('请选择通知类型');
         return false;
       }
       var ids = [];
       this.GoodPositionTable.forEach(item=> {
         ids.push(item.targetId)
       })
       var conditionItemList6 = [];
       this.goodPositionList.forEach(item=> {
         conditionItemList6.push({
           conditional: item.conditional,   // 执行条件
           compare: item.compare,   // 判断大小
           value: item.amount,    // 输入金额
           conditionalRange: this.goodsPositValue,    // 应用时间
         })
       })
       var timeItemList6 = [];
       this.addTimeRangeByWeek6.forEach(data=> {
         timeItemList6.push({
           changeNumber: data.inputAmount,   // 	数值
           changeType: data.modifyWay,   // 修改方式
           startTime: data.startTime, //  开始时间
           endTime: data.endTime,   // 结束时间
           dayNumber: data.severalWeeks,   //  选择天 周几
         })
       })
       let data = {
         name: this.ruleForm.taskName,  // 任务名称
         objectType: "targets",
         type: this.ruleForm.createPosition,  // 任务类型
         adType: this.ruleForm.adTypes,   // 广告类型
         objectRange: ids.join(),   // 应用范围
         conditionItemList: conditionItemList6,   // 执行条件
         executeType: this.frequencyType,   // 执行周期
         executeDate: this.goodsTimePoint1.slice(0, 10),
         executeDay: this.goodExecuteDay,
         executeTime: this.frequencyType=='0'?this.goodsTimePoint1.slice(11, 16):this.goodsPosiTimePoint,
         dayType: this.goodsDayType,
         notificationEmail: this.goodEmailAccount,
         notificationType: this.goodNoticeTime,
         operationType: this.goodOperaType,
         operationNumber: parseInt(this.goodsPositionNumber),
         operationMode: this.goodsMode,
         operationMaxLimit: parseInt(this.goodsMaxLimit),
         operationMinLimit: parseInt(this.goodsMinLimit),
         timeDivisionBudget: parseInt(this.timeDivisionBudget3),  // 	分时竞价的基础预算
         timeItemList: timeItemList6,  //  执行时间list  
         profileId: this.pageInfo.profileId,
         id: this.type6 == 2?this.editId: "",
       }  
       if(type6 == 1) {
          addScheduled(data)
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.priductPositionVisible = false;
              this.asyncSearch();  // 刷新页面
            }
          })
       }else if(type6 == 2){
         editScheduled(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.priductPositionVisible = false;
             this.asyncSearch();  // 刷新页面
           }
         })
       }

     },
     handleAutoTargetToggle(item, index7) {
       this.autoTargetList[index7].conditional = item.id;
       this.autoTargetList[index7].conditionsvalues = item.values;
     },
     handleConfirm2() {
       for (let f=0; f<this.autoTargetList.length; f++) {
        if(!this.autoTargetList[f].amount) {
          this.$message.error("执行条件数值不可为空");
          return false;
        }
       }
       this.dynamicTags5 = this.autoTargetList.map(data=> {
         return data.conditionsvalues + data.judgename + data.amount;
       })
       this.autoTargetVisible = false;
       this.autoTargeteTimeRange = true;
     },
     handleAutoTargetToggle2(item, index7) {
       this.autoTargetList[index7].compare = item.id;
       this.autoTargetList[index7].judgename = item.name;
       this.autoTargetList[index7].judgevalues = item.values;
     },
     handleClose7(tag, index7) {
       this.dynamicTags5.splice(this.dynamicTags5.indexOf(tag), 1);
       this.autoTargetList.forEach((item, _self7)=> {
         if(index7 == _self7) {
            this.autoTargetList.splice(_self7, 1);
         }
       })
     },
     handleIncrease() {
       this.autoTargetList.push({
         percentShow: false,
         JPYShow: false,
         conditional: "",   // 执行条件
         conditionsvalues: "",
         compare: "",   // 判断大小
         judgevalues: "",
         judgename: "",
         amount: ""    // 数值金额
       })
     },
     handleTargetEvent(index7) {
       this.autoTargetList.splice(index7, 1);
     },
     addTimeTargetRange() {
       if(this.autoTargetdayType == "1") {
        this.addTimeRangeByWeek7.push({
          startTime: "",  
          endTime: "",
          modifyWay: "",     //  修改方式
          inputAmount: "",   // 输入金额
        })
       }else if(this.autoTargetdayType == "2") {
         this.addTimeRangeByWeek7.push({
           severalWeeks: "",
           startTime: "",
           endTime: "",
           modifyWay: "",   // 修改方式
           inputAmount: ""  // 输入金额
         })
       }  
     },
     handlePresetIn2(val) {
       this.addTimeRangeByWeek7 = [];
       if(val == 1) {
         this.addTimeRangeByWeek7.push({
           startTime: "", 
           endTime: "",
           modifyWay: "",     //  修改方式
           inputAmount: "",   // 输入金额
         })
       }else if(val == 2) {
         this.addTimeRangeByWeek7.push({
           severalWeeks: "",
           startTime: "", 
           endTime: "",
           modifyWay: "",    // 修改方式
           inputAmount: ""   // 输入金额
         })
       }
     }, 
     // 新增自动投放 
     addAutoTarget(type7) {
       if(!this.dynamicdateForm.taskName) {
         this.$message.error('定时任务名称不能为空');
         return false;
       }
       if(!this.dynamicdateForm.autoTarget) {
         this.$message.error('定时任务类型不能为空');
         return false; 
       }
       if(!this.dynamicdateForm.autoApplicaRange) {
         this.$message.error('应用范围不能为空');
         return false; 
       }
       if(!this.autoNoticeTime) {
         this.$message.error('请选择通知类型');
         return false;
       }
       var ids = [];
       this.autoTableList.forEach(data=> {
         ids.push(data.targetId)
       })
       var conditionItemList7 = [];
       this.autoTargetList.forEach(item=> {
         conditionItemList7.push({
           conditional: item.conditional,   // 执行条件
           compare: item.compare,   // 判断大小
           value: item.amount,    // 输入金额
           conditionalRange: this.autoTargeteValue,    // 应用时间
         })
       })
       var timeItemList7 = [];
       this.addTimeRangeByWeek7.forEach(item=> {
         timeItemList7.push({
           changeNumber: item.inputAmount,
           changeType: item.modifyWay,
           startTime: item.startTime,
           endTime: item.endTime,
           dayNumber: item.severalWeeks,   // 选择天数  周几
         })
       })
       let data = {
         name: this.dynamicdateForm.taskName,   // 任务名称
         objectType: "auto",
         type: this.dynamicdateForm.autoTarget, // 任务类型
         adType: this.dynamicdateForm.autoTargetTypes, // 广告类型
         objectRange: this.dynamicdateForm.autoApplicaRange == "ids"?ids.join():this.dynamicdateForm.autoApplicaRange,  // 应用对象范围
         conditionItemList: conditionItemList7,  // 执行条件
         executeType: this.autoTargetFrequency,  //  执行周期
         executeDay: this.autoExecuteDay,    // 执行周期  每周(0-6)  每月(1-31)
         executeDate: this.targetTimePoint1.slice(0, 10),
         executeTime: this.autoTargetFrequency=='0'?this.targetTimePoint1.slice(11, 16):this.autargetTimePoint,
         dayType: this.autoTargetdayType,
         notificationEmail: this.autoEmailAccount,  // 通知email
         notificationType: this.autoNoticeTime,  // 通知类型
         operationType: this.autoTargetType,  // 操作类型
         operationNumber: parseInt(this.autoTargetAmount),  // 调整金额比例
         operationMode: this.autoTargetMode,   // 操作方式
         operationMaxLimit: parseInt(this.autoTargetMaxLimit),
         operationMinLimit: parseInt(this.autoTargetMinLimit),
         timeDivisionBudget: parseInt(this.timeDivisionTarget),
         timeItemList: timeItemList7,  //  执行时间list
         profileId: this.pageInfo.profileId,
         id: this.type7 == 2?this.editId: "",
       }
       if(type7 == 1) {
         addScheduled(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.targetVisible = false;
             this.asyncSearch();  // 刷新页面
             this.$nextTick(()=> {
               this.$refs.multipleTable7.clearSelection();
             })
           }
         })
       }else if(type7 == 2) {
        editScheduled(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.targetVisible = false;
             this.asyncSearch();  // 刷新页面
             this.$nextTick(()=> {
               this.$refs.multipleTable7.clearSelection();
             })
           }
         })
       }
     },
     handleAddEvent() {
       this.goodPositionList.push({
         percentShow: false,
         JPYShow: false,
         conditional: "",   // 执行条件
         conditionsvalues: "",
         compare: "",  // 判断大小
         judgevalues: "",
         judgename: "",
         amount: ""    // 数值金额
       }) 
     },
     deleteGoodsEvent(index6) {
       this.goodPositionList.splice(index, 1);
     },
     handleConfirm3() {
       for (let n= 0; n<this.goodPositionList.length; n++) {
        if(!this.goodPositionList[n].amount) {
          this.$message.error("执行条件数值不可为空");
          return false;
        }
       }
       this.dynamicTags6 = this.goodPositionList.map(data=> {
         return data.conditionsvalues + data.judgename + data.amount;
       })
       this.goodPositionVisible = false;
       this.goodsPosiTimeRange = true;
     },
     toggleGoodPosition(item, index6) {
       this.goodPositionList[index6].conditionsvalues = item.values;
       this.goodPositionList[index6].conditional = item.id;
     },
     toggleGoodPosition2(item, index6) {
       this.goodPositionList[index6].compare = item.id;
       this.goodPositionList[index6].judgevalues = item.values;
       this.goodPositionList[index6].judgename = item.name;
     },
     handleClose6(tag, index6) {
       this.dynamicTags6.splice(this.dynamicTags6.indexOf(tag), 1);
       this.goodPositionList.forEach((item, _self6)=> {
         if(index6 == _self6) {
            this.goodPositionList.splice(_self6, 1);
         }
       })
     }, 
     addTimeGoodsRange() {
       if(this.goodsDayType == "1") {
        this.addTimeRangeByWeek6.push({
          startTime : "", 
          endTime: "",
          modifyWay: "",     //  修改方式
          inputAmount: "",   // 输入金额
        })
       }else if(this.goodsDayType == "2") {
         this.addTimeRangeByWeek6.push({
           severalWeeks: "",
           startTime : "", 
           endTime: "",
           modifyWay: "",    // 修改方式
           inputAmount: ""   // 输入金额
         })
       }  
     },
     handlePresetIn3(val) {
       this.addTimeRangeByWeek6 = [];
       if(val == 1) {
         this.addTimeRangeByWeek6.push({
           startTime : "", 
           endTime: "",
           modifyWay: "",     //  修改方式
           inputAmount: "",   // 输入金额
         })
       }else if(val == 2) {
         this.addTimeRangeByWeek6.push({
           severalWeeks: "",
           startTime : "", 
           endTime: "",
           modifyWay: "",    // 修改方式
           inputAmount: ""   // 输入金额
         })
       }
     }, 
     handleDeleteByTarget(index) {
       this.addTimeRangeByWeek7.splice(index, 1);
     },
     handleRemoveGoods(index) {
       this.addTimeRangeByWeek6.splice(index, 1);
     },
     // 编辑初始化
     handleEdit(row) {
       this.editId = row.id;
       getEditInit(row.id)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message)
         }else {
           if(res.data.data.objectType == "campaigns") {       // 广告活动
             this.form.taskName = res.data.data.name;    // 任务名称
             this.form.activityType = res.data.data.type,  // 为活动创建
             this.form.adTypes = res.data.data.adType,   //  广告类型
             this.form.applicaRange = res.data.data.objectRange;   // 应用范围
             this.dynamicTags = res.data.data.conditionItemList.map(data=> {
               return data.conditionalName + data.compareName + data.value;
             })
             if(this.dynamicTags.length>0) {
               var arrList = [];
               res.data.data.conditionItemList.forEach(data=> {
                 let object = {
                   conditional: data.conditional,
                   compare: (data.compare).toString(),
                   amount: data.value,
                   conditionsvalues: data.conditionalName,
                   judgename: data.compareName
                 }
                 arrList.push(object);
               })
               this.dynamicDomain = arrList;
             }
             if(this.dynamicTags.length>0) {
               res.data.data.conditionItemList.forEach(item=> {
                 this.adCampaignTimeRangeValue = item.conditionalRange;
               })
               this.adCampaignTimeRange = true;
             }
             this.strategyType = res.data.data.strategyType;   // 在执行频率中将竞价策略调整到
             this.campaignFrequency = (res.data.data.executeType).toString();  // 执行频率(	执行周期)
             if(res.data.data.executeType == 0) {    // 执行频率为一次
               this.chooseDateTime = res.data.data.executeDate + " " + res.data.data.executeTime;
             }
             this.adCampaignExecuteDay = (res.data.data.executeDay).toString();  // 选择天数
             this.chooseTimePoint = res.data.data.executeTime; 
             this.executeType1 = res.data.data.operationType;    //
             this.operationMode = res.data.data.operationMode;   // 选择模式
             this.operationNumber = res.data.data.operationNumber;  // 输入金额
             this.operationMaxLimit = res.data.data.operationMaxLimit;  // 最大金额
             this.operationMinLimit = res.data.data.operationMinLimit;  // 最小金额
             this.notificationEmail = res.data.data.notificationEmail;  // 通知邮箱
             this.noticeTime = (res.data.data.notificationType).toString();
             this.dayType = (res.data.data.dayType).toString();
             this.timeDivisionBudget = res.data.data.timeDivisionBudget;  // 分时调预算
             var campaignArray = [];
             res.data.data.timeItemList.forEach(data=> {
               let object = {
                 severalWeeks: (data.dayNumber).toString(),
                 modifyWay: (data.changeType).toString(),
                 inputAmount: data.changeNumber,
                 startTime: data.startTime,
                 endTime: data.endTime
               }
               campaignArray.push(object)
             })
             this.addTimeRangeByWeek = campaignArray; 
             this.tableDataArray = res.data.data.objectDtoList;
             this.type1 = 2;
             this.adCampaignVisible = true;
           }else if(res.data.data.objectType == "groups") {    // 广告组(编辑回显)
              this.formData.taskName = res.data.data.name;     // 任务名称
              this.formData.adGroupType = res.data.data.type;  // 为广告组创建
              this.formData.adGroupTypes = res.data.data.adType;   // 广告类型
              this.formData.adGroupAppRange = res.data.data.objectRange;   // 应用范围
              this.adGroupOperation = res.data.data.operationType;   // 操作类型(fixed up down)
              this.adGroupNumber = res.data.data.operationNumber;    // 操作类型金额
              this.adGroupMode = res.data.data.operationMode;        // 操作模式
              this.adGroupMinLimit = res.data.data.operationMinLimit;  // 最大金额
              this.adGroupMaxLimit = res.data.data.operationMaxLimit;  // 最小金额
              this.dynamicTags2 =  res.data.data.conditionItemList.map(data=> {         // 执行条件
                return data.conditionalName + data.compareName + data.value;
              });   
              if(this.dynamicTags2.length>0) {
                var arrList2 = [];
                res.data.data.conditionItemList.forEach(data=> {
                  let object = {
                    conditional: data.conditional,
                    compare: (data.compare).toString(),
                    amount: data.value,
                    conditionsvalues: data.conditionalName,
                    judgename: data.compareName
                  }
                  arrList2.push(object);
                })
                this.gridData = arrList2;
              }
              if(this.dynamicTags2.length>0) {
                res.data.data.conditionItemList.forEach(item=> {
                  this.adGroupValue = item.conditionalRange;   //  执行条件的时间范围
                })
                this.adGroupTimeRange = true;
              }         
              this.adGroupTableList = res.data.data.objectDtoList;
              this.adGroupFrequency = (res.data.data.executeType).toString();   //  执行频率
              if(res.data.data.executeType == 0) {    // 执行频率为一次
                this.adGroupDateTime = res.data.data.executeDate + " " + res.data.data.executeTime;
              }
              this.adGroupExecuteDay = (res.data.data.executeDay).toString();   //  选择天数
              this.adGroupTimePoint = res.data.data.executeTime;   //  选择时间
              this.adGroupEmail = res.data.data.notificationEmail;
              this.adGroupNoticeTime = (res.data.data.notificationType).toString();
              this.type2 = 2;  
              this.adGroupVisible = true;
           }else if(res.data.data.objectType == "pages") {     //  广告位
             this.$refs.subComponents.formModel.taskName = res.data.data.name;
             this.$refs.subComponents.formModel.createAdverte = res.data.data.type;
             this.$refs.subComponents.formModel.createAdverte = res.data.data.type;
             this.$refs.subComponents.formModel.adverApplicaRange = res.data.data.objectRange;
             this.$refs.subComponents.operationTop = res.data.data.operationTop;  
             this.$refs.subComponents.operationProduct = res.data.data.operationProduct;
             this.$refs.subComponents.dynamicTags3 = res.data.data.conditionItemList.map(data=> {
               return data.conditionalName + data.compareName + data.value;
             })
             if(this.$refs.subComponents.dynamicTags3.length>0) {
               var arrList3 = [];
               res.data.data.conditionItemList.forEach(data=> {
                 let object = {
                  conditional: data.conditional,
                  compare: (data.compare).toString(),
                  amount: data.value,
                  conditionsvalues: data.conditionalName,
                  judgename: data.compareName
                 }
                 arrList3.push(object);
               })
               this.$refs.subComponents.collectionList = arrList3;
             }
             if(this.$refs.subComponents.dynamicTags3.length>0) {
               res.data.data.conditionItemList.forEach(data=> {
                 this.$refs.subComponents.conditionalTimeRangeValue = data.conditionalRange;
                 this.$refs.subComponents.adTimeRangeShow = true;
               })
             }
             this.$refs.subComponents.frequencyType = (res.data.data.executeType).toString();   // 执行频率
             if(res.data.data.executeType == 0) {      //  执行频率为一次
               this.$refs.subComponents.adverDateTime = res.data.data.executeDate + " " + res.data.data.executeTime;
             };
             this.$refs.subComponents.advertiseTableData = res.data.data.objectDtoList;
             this.$refs.subComponents.advertiseTableShow = true;
             this.$refs.subComponents.executeDay = (res.data.data.executeDay).toString();
             this.$refs.subComponents.adverTimePoint = res.data.data.executeTime;
             this.$refs.subComponents.adverEmail = res.data.data.notificationEmail; 
             this.$refs.subComponents.adverNoticeTime = (res.data.data.notificationType).toString();
             this.$refs.subComponents.type3 = 2;
             this.$refs.subComponents.advertisingVisible = true;
           }else if(res.data.data.objectType == "product") {   // 广告
             this.formInline.taskName =res.data.data.name;       //  任务名称
             this.formInline.createAdver = res.data.data.type;   //  创建类型
             this.formInline.advertisingTypes = res.data.data.adType;   //  广告类型
             this.formInline.advertiseApplicaRange = res.data.data.objectRange;  //  应用范围
             this.dynamicTags3 = res.data.data.conditionItemList.map(data=> {  
                return data.conditionalName + data.compareName + data.value;
             }); 
             if(this.dynamicTags3.length>0) {
               var arrList4 = [];
               res.data.data.conditionItemList.forEach(data=> {
                 let object = {
                  conditional: data.conditional,
                  compare: (data.compare).toString(),
                  amount: data.value,
                  conditionsvalues: data.conditionalName,
                  judgename: data.compareName
                 }
                 arrList4.push(object);
               })
               this.adCollection = arrList4;
             }
             if(this.dynamicTags3.length>0) {
               res.data.data.conditionItemList.forEach(data=> {
                 this.adTimeRangeValue = data.conditionalRange;
               })
               this.adverTimeRange = true;
             }    
             this.adTableList = res.data.data.objectDtoList;
             this.performFrequency = (res.data.data.executeType).toString();  // 执行频率
             if(res.data.data.executeType == 0) {   // 执行频率为一次
               this.adDateTime = res.data.data.executeDate + " " + res.data.data.executeTime; 
             }
             this.adExecuteDay = (res.data.data.executeDay).toString();       // 选择天数
             this.adTimePoint = res.data.data.executeTime;    // 时间点
             this.adEmailAccount = res.data.data.notificationEmail;   // 通知email
             this.adSendResult = (res.data.data.notificationType).toString();  // 执行类型
             this.type4 = 2;
             this.advertiseVisible = true;
           }else if(res.data.data.objectType == "keywords") {  // 关键词
             this.formLabelAlign.taskName = res.data.data.name;   // 任务名称
             this.formLabelAlign.createKeyword = res.data.data.type;  //  创建类型
             this.formLabelAlign.keywordsAdTypes = res.data.data.adType;  // 广告类型
             this.keywordApplicaRange = res.data.data.objectRange;    // 应用范围
             this.keywordTableList = res.data.data.objectDtoList;    // table 列表数据 
             this.keywordsOperaType = res.data.data.operationType;  // 操作类型
             this.keywordNumber = res.data.data.operationNumber;  // 输入金额
             this.keywordMode = res.data.data.operationMode;    // 选择类型
             this.keywordMinLimit = res.data.data.operationMinLimit;  // 最小金额
             this.keywordMaxLimit = res.data.data.operationMaxLimit;  // 最大值   
             this.dynamicTags4 = res.data.data.conditionItemList.map(data=> {     // 执行条件
                return data.conditionalName + data.compareName + data.value;
             });
             if(this.dynamicTags4.length>0) {
               var arrList5 = [];
               res.data.data.conditionItemList.forEach(data=> {
                 let object = {
                  conditional: data.conditional,
                  compare: (data.compare).toString(),
                  amount: data.value,
                  conditionsvalues: data.conditionalName,
                  judgename: data.compareName
                 }
                 arrList5.push(object);
               })
               this.LoopListData = arrList5;
             }  
             if(this.dynamicTags4.length>0) {
               res.data.data.conditionItemList.forEach(data=> {
                 this.KeywordValue = data.conditionalRange;   // 执行条件时间范围
               })
               this.KeywordTimeRange2 = true;
             }
             this.keywordDayType = (res.data.data.dayType).toString();
             this.timeDivisionBudget2 = res.data.data.timeDivisionBudget;
             var keywordArray = [];
             res.data.data.timeItemList.forEach(data=> {
               let object = {
                 severalWeeks: (data.dayNumber).toString(),
                 modifyWay: (data.changeType).toString(),
                 inputAmount: data.changeNumber,
                 startTime: data.startTime,
                 endTime: data.endTime
               }
               keywordArray.push(object)
             })
             this.addTimeRangeByKeyword = keywordArray;   // 执行时间list
             this.keywordFrequency = (res.data.data.executeType).toString();   // 执行频率
             if(res.data.data.executeType == 0) {   // 执行频率为 0
                this.keywordTimePoint1 = res.data.data.executeDate + " " + res.data.data.executeTime;
             }
             this.keywordExecuteDay = (res.data.data.executeDay).toString();   //  选择天数
             this.chkeywordTimePoint = res.data.data.executeTime;  // 选择时间
             this.keywordEmailAccount = res.data.data.notificationEmail;  //  通知email
             this.keywordNoticeTime = (res.data.data.notificationType).toString();  // 	执行类型
             this.type5 = 2;
             this.keywordVisible = true;
           }else if(res.data.data.objectType == "targets") {   // 商品定位
             this.ruleForm.taskName = res.data.data.name;      // 任务名称
             this.ruleForm.createPosition = res.data.data.type;  // 创建类型
             this.ruleForm.adTypes = res.data.data.adType;    // 广告类型
             this.goodsApplicaRange = res.data.data.objectRange;     // 应用范围
             this.GoodPositionTable = res.data.data.objectDtoList;   // table 表格数据
             this.goodsDayType = (res.data.data.dayType).toString();    // 选择类型
             this.timeDivisionBudget3 = res.data.data.timeDivisionBudget;   //  分时调整预算
             var goodsArray = [];
             res.data.data.timeItemList.forEach(data=> {
               let object = {
                 severalWeeks: (data.dayNumber).toString(),
                 modifyWay: (data.changeType).toString(),
                 inputAmount: data.changeNumber,
                 startTime: data.startTime,   // 开始时间
                 endTime: data.endTime   // 结结束时间
               }
               goodsArray.push(object);
             })
             this.addTimeRangeByWeek6 = goodsArray;  // 执行时间list(数据需处理)
             this.dynamicTags6 = res.data.data.conditionItemList.map(data=> {    // 执行条件
                return data.conditionalName + data.compareName + data.value;
             });  
             if(this.dynamicTags6.length>0) {
               var arrList6 = [];
               res.data.data.conditionItemList.forEach(data=> {
                 let object = {
                  conditional: data.conditional,
                  compare: (data.compare).toString(),
                  amount: data.value,
                  conditionsvalues: data.conditionalName,
                  judgename: data.compareName
                 }
                 arrList6.push(object);
               })
               this.goodPositionList = arrList6;
             }
             if(this.dynamicTags6.length>0) {
               res.data.data.conditionItemList.forEach(item=> {
                 this.goodsPositValue = item.conditionalRange;
               })
               this.goodsPosiTimeRange = true;
             } 
             this.frequencyType = (res.data.data.executeType).toString();  // 执行频率
             if(res.data.data.executeType == 0) {   // 执行频率为一次
               this.goodsTimePoint1 = res.data.data.executeDate + " " + res.data.data.executeTime;
             }
             this.goodExecuteDay = (res.data.data.executeDay).toString();  // 选择天数
             this.goodsPosiTimePoint = res.data.data.executeTime;
             this.goodOperaType = res.data.data.operationType;   // 操作类型
             this.goodsPositionNumber = res.data.data.operationNumber;  // 输入金额
             this.goodsMode = res.data.data.operationMode;   //  选择类型
             this.goodsMinLimit = res.data.data.operationMinLimit;  // 最小金额
             this.goodsMaxLimit = res.data.data.operationMaxLimit;  // 最大金额
             this.goodEmailAccount = res.data.data.notificationEmail;  // 通知email
             this.goodNoticeTime = (res.data.data.notificationType).toString();  // 通知类型
             this.timeDivisionBudget3 = res.data.data.timeDivisionBudget;
             this.type6 = 2;
             this.priductPositionVisible = true;
           }else if(res.data.data.objectType == "auto") {      // 自动投放
             this.dynamicdateForm.taskName = res.data.data.name;    // 任务名称
             this.dynamicdateForm.autoTarget = res.data.data.type;  // 创建类型
             this.dynamicdateForm.autoTargetTypes = res.data.data.adType;  // 广告类型
             this.dynamicdateForm.autoApplicaRange = res.data.data.objectRange;  // 应用范围
             this.autoTableList = res.data.data.objectDtoList;     // table 表格数据
             this.dynamicTags5 = res.data.data.conditionItemList.map(data=> {    // 执行条件
               return data.conditionalName + data.compareName + data.value;
             });  
             if(this.dynamicTags5.length>0) {
               var arrList7 = [];
               res.data.data.conditionItemList.forEach(data=> {
                 let object = {
                  conditional: data.conditional,
                  compare: (data.compare).toString(),
                  amount: data.value,
                  conditionsvalues: data.conditionalName,
                  judgename: data.compareName
                 }
                 arrList7.push(object);
               })
               this.autoTargetList = arrList7;
             }
             if(this.dynamicTags5.length>0) {
               res.data.data.conditionItemList.forEach(data=> {
                 this.autoTargeteValue = data.conditionalRange;
               })
               this.autoTargeteTimeRange = true;
             }
             this.autoTargetFrequency = (res.data.data.executeType).toString();  // 执行频率
             if(res.data.data.executeType == 0) {   // 执行频率为一次
               this.targetTimePoint1 = res.data.data.executeDate + " " + res.data.data.executeTime;
             }
             this.autoTargetdayType = (res.data.data.dayType).toString();
             this.timeDivisionTarget = res.data.data.timeDivisionBudget;
             var targetArry = [];
             res.data.data.timeItemList.forEach(data=> {
               let object = {
                 severalWeeks: (data.dayNumber).toString(),
                 modifyWay: (data.changeType).toString(),
                 inputAmount: data.changeNumber,
                 startTime: data.startTime,
                 endTime: data.endTime,
               }
               targetArry.push(object)
             })
             this.addTimeRangeByWeek7 = targetArry;   // 执行时间list数据需处理)
             this.autoExecuteDay = (res.data.data.executeDay).toString();  // 选择天数
             this.autargetTimePoint = res.data.data.executeTime;    // 执行时间
             this.autoTargetType = res.data.data.operationType;     // 操作类型
             this.autoTargetAmount = res.data.data.operationNumber; // 输入金额
             this.autoTargetMode = res.data.data.operationMode;   // 选择模式
             this.autoTargetMinLimit = res.data.data.operationMinLimit;  // 最小值
             this.autoTargetMaxLimit = res.data.data.operationMaxLimit;  // 最大值
             this.autoEmailAccount = res.data.data.notificationEmail;
             this.autoNoticeTime = (res.data.data.notificationType).toString(); // 通知类型
             this.type7 = 2;
             this.targetVisible = true;
           }
         }
       })
     },
     handleSwitchClick(tab) {
       if(tab.name == "executionRecord") {
         this.handle_Search();
       }
     },
     // 执行记录查询
     handle_Search() {
       let params = {
         keyword: this.searchInfo.keyword,
         current: this.searchInfo.current,
         pageSize: this.searchInfo.pageSize,
         startDate: this.searchInfo.startDate,
         endDate: this.searchInfo.endDate,
         shopId: this.pageInfo.shopId,
         marketplaceId: this.pageInfo.marketplaceId
       }
       getRecordListPage(params)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.tableData2 = res.data.values;
           this.totalRecords = res.data.pageInfo.total;
         }
       })
     },
     getOpRecordList() {
       let params = {
         current: this.pageInfoOprecords.current,
         pageSize: this.pageInfoOprecords.pageSize,
         recordId: this.recordId
       }
       getOpRecorddetail(params)
       .then(res=> {
         this.adOperationList = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.adOperationList = res.data.values;
           this.totalOprecords = res.data.pageInfo.total;
         }
       })
     },
     // 广告操作记录
     OpRecordsClick(row) {
       this.recordId = row.id;
       this.dialogTitle = row.scheduleName;
       this.getOpRecordList();
       this.operationVisible = true;
     },
     handleSizeOprecords(newSize) {
       this.pageInfoOprecords.pageSize = newSize;
       this.getOpRecordList();
     },
     handleCurrentOprecords(newCurrent) {
       this.pageInfoOprecords.current = newCurrent;
       this.getOpRecordList();
     },
     handleExecutionresult(row) {
       this.ExecutionId = row.id;
       this.executionTitle = row.scheduleName;
       this.taskRules = row.type;
       this.getExecutionresultList();
       this.executionVisible = true;
     },
     handleClick(tab) {
      if(tab.name == "totalNumber") {
       this.getExecutionresultList(); // 查询所有
      }else if(tab.name == "successNumber") {
        this.getExecutionBySuccess();   // 查询成功
      }else if(tab.name == "defeatedNumber") {
        this.getExecutionByDefeated();   // 查询失败
      }
     },
     getExecutionresultList() {
       let params = {
         current: this.pageInfoAmount.current,
         pageSize: this.pageInfoAmount.pageSize,
         type: null,
         recordId: this.ExecutionId
       }
       getresultListPage(params)
        .then(res=> {
          console.log(res);
          this.sumNumberTableList = [];
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.sumNumberTableList = res.data.values;
            this.totalAmount = res.data.pageInfo.total;
            this.performNumber = res.data.mapInfo.total;
            this.performSuccessNumber = res.data.mapInfo.success;
          }
        })
     },
     getExecutionBySuccess() {
       let params = {
         current: this.pageInfoSuccess.current,
         pageSize: this.pageInfoSuccess.pageSize,
         type: 1,
         recordId: this.ExecutionId
       }
       getresultListPage(params)
       .then(res=> {
         this.successTableList = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.successTableList = res.data.values;
           this.totalSuccess = res.data.pageInfo.total;
         }
       })
     },
     getExecutionByDefeated() {
       let params = {
         current: this.pageInfoDefeated.current,
         pageSize: this.pageInfoDefeated.pageSize,
         type: 0,
         recordId: this.ExecutionId
       }
       getresultListPage(params)
        .then(res=> {
          this.defeatedTableList = [];
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.defeatedTableList = res.data.values;
            this.totalDefeated = res.data.pageInfo.total;
          }
        })
     },
     handleSizeAmount(newsize) {
       this.pageInfoAmount.pageSize = newsize;
       this.getExecutionresultList();
     },
     handleCurrentAmount(newCurrent) {
       this.pageInfoAmount.current = newCurrent;
       this.getExecutionresultList();
     },
     handleSizeSuccess(newSize) {
       this.pageInfoSuccess.pageSize = newSize;
       this.getExecutionBySuccess();
     },
     handleCurrentSuccess(newCurrent) {
       this.pageInfoSuccess.current = newCurrent;
       this.getExecutionBySuccess();
     },
     handleSizeDefeated(newSize) {
       this.pageInfoDefeated.pageSize = newSize;
       this.getExecutionByDefeated();
     },
     handleCurrentDefeated(newCurrent) {
       this.pageInfoDefeated.current = newCurrent;
       this.getExecutionByDefeated();
     }
   }
}
</script>

<style lang="scss" scoped>
.storage {
  .append {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .seach {
      width: 100%;
      .el-tabs {
        width: 100%;
        margin-left: 10px;
        .el-input {
          width: 180px;
        }
        .el-button {
          margin-right: 15px;
        }
        .el-select {
          margin-right: 15px;
        }
        .el-input {
          margin-right: 15px;
        }
      }
    }

  }
}
.removeClass {
  width: 16px;
  height: 16px;
  cursor:pointer;
}
.deleteButton {
  margin-left: 8px;
  cursor: pointer;
  font-size: 15px;
}
</style>